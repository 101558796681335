let date = new Date();
const currentDay = `0${new Date().getDate()}`.slice(-2);
const currentMonth = `0${new Date().getMonth() + 1}`.slice(-2);
const currentYear = new Date().getFullYear();
const currentHour = date.getHours();
const currentMinutes = String(date.getMinutes()).padStart(2, "0");
const currentSeconds = date.getSeconds();

var newDate = new Date().toISOString();

export const today = `${currentYear}-${currentMonth}-${currentDay}`;
export const currentTime = `${currentHour}:${currentMinutes}`;
export const month = `${currentYear}-${currentMonth}`;

export const getCurrentTime = () => {
  let dateFn = new Date();
  let currentTime = "00:00:00";
  const currentHour = dateFn.getHours();
  const currentMinutes = String(dateFn.getMinutes()).padStart(2, "0");
  const currentSeconds = String(dateFn.getSeconds()).padStart(2, "0");
  currentTime = `${currentHour}:${currentMinutes}:${currentSeconds}`;
  return currentTime;
};
export const getCurrentTimeWOsec = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  // Format time as HH:MM
  const currentTime = `${hours}:${minutes}`;
  return currentTime;
};
//export const updateDate = (new Date(newDate.setDate(newDate.getDate() - 10)).toDateString())
//export const updateDate = newDate.setDate(newDate.getDate() - parseInt(10));
export const previousDate = new Date(
  new Date().setDate(new Date().getDate() - 30)
)
  .toISOString()
  .split("T")[0];
export const dateAfterFiveDays = new Date(
  new Date().setDate(new Date().getDate() + 5)
)
  .toISOString()
  .split("T")[0];

export const newCurrentTime = new Date().toLocaleTimeString();

//Convert time format from YYYY-MM-DD to DD-MM-YYYY
export const convertDateFormat = (input) => {


  
  if (typeof input === 'string') {
    let datePart = input.match(/\d+/g);
    let year = datePart !== null && datePart[0], // get 4 digits
      month = datePart !== null && datePart[1],
      day = datePart !== null && datePart[2];

    return day + "/" + month + "/" + year;
  }
};

//Return digit month into work month [01 -> Jan]
export const convertMonthToWord = (date = "0000-00-00") => {
  let monthInWord = null;
  let [year, month, day] = date.split("-");

  switch (month) {
    case "01":
      monthInWord = "Jan";
      break;
    case "02":
      monthInWord = "Feb";
      break;
    case "03":
      monthInWord = "Mar";
      break;
    case "04":
      monthInWord = "Apr";
      break;
    case "05":
      monthInWord = "May";
      break;
    case "06":
      monthInWord = "Jun";
      break;
    case "07":
      monthInWord = "Jul";
      break;
    case "08":
      monthInWord = "Aug";
      break;
    case "09":
      monthInWord = "Sep";
      break;
    case "10":
      monthInWord = "Oct";
      break;
    case "11":
      monthInWord = "Nov";
      break;
    case "12":
      monthInWord = "Dec";
      break;

    default:
      monthInWord = "##";
      break;
  }
  return monthInWord;
};

//Convert meridiem time to hh:mm:ss format
export const convertMeridiemToIso = (
  hour = "0",
  minute = "0",
  meridiem = "AM"
) => {
  let newHour = null;
  if (meridiem === "PM") {
    if (hour === "12") {
      newHour = hour;
    } else {
      newHour = Number(hour) + 12;
    }
  } else {
    if (hour === "12") {
      newHour = "00";
    } else {
      newHour = hour;
    }
  }

  return `${newHour}:${minute}:00`;
};


export const convertToAMPM=(time24)=> {
  if(time24){
    const [hours, minutes, seconds] = time24.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hour = hours % 12 || 12;
    return `${hour}:${minutes.toString().padStart(2, '0')} ${period}`
  }
}


export const getTimeDifference=(givenDateTime)=> {
  const [givenDate, givenTime] = givenDateTime.split(' '); // Split date and time
  const [givenYear, givenMonth, givenDay] = givenDate.split('-').map(Number); // Extract year, month, day
  const [givenHour, givenMinute, givenSecond] = givenTime.split(':').map(Number); // Extract hour, minute, second

  const givenDateTimeObj = new Date(givenYear, givenMonth - 1, givenDay, givenHour, givenMinute, givenSecond); // Month needs to be 0-indexed in JavaScript

  const currentDateTime = new Date();

  const timeDifferenceMillis = currentDateTime - givenDateTimeObj;

  if (timeDifferenceMillis < 0) {
    return "00:00:00";
  }

  const totalSeconds = Math.floor(timeDifferenceMillis / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}


export const getTimer = (startDateTime) => {
  const startTime = new Date(startDateTime);

  const endTime = new Date();

  const timeDiffInMilliseconds = endTime - startTime;
  
  if (timeDiffInMilliseconds < 0) {
      return "00:00:00";
  }

  if (isNaN(startTime.getTime())) {
    return "00:00:00";
}
  const timeDiffInSeconds = Math.floor(timeDiffInMilliseconds / 1000);

  const hours = String(Math.floor(timeDiffInSeconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((timeDiffInSeconds % 3600) / 60)).padStart(2, '0');
  const seconds = String(Math.floor(timeDiffInSeconds % 60)).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};