import React from "react";
import { useSelector } from "react-redux";

function TaxDetailsSummary({ showSubHeaders }) {
  const ShiftBillTypeSummary = useSelector(
    (state) => state.posReportshiftSlice.taxDetails
  );
  const decimalPosition = localStorage.getItem("decimalPosition");
  const getBackgroundColor = (index) => {
    return index % 2 === 0 ? "#ffffff" : "#E6E6E6";
  };

  return (
    <div
      className="new-global-table-container"
      style={{ paddingTop: "0 42px" }}
    >
      <div className="bill-main-head">
        <h5>Bill Type</h5>
        <h5>Tax Amount</h5>
      </div>
      <div
        className="bill-sub-head"
        style={{
          backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(0),
        }}
      >
        <h5>Dine In</h5>
        <h5>
          {(ShiftBillTypeSummary?.dineIn?.totalTax || 0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Order ID</th>
              <th>Customer</th>
              <th>Tax Name</th>
              <th>Order Amount</th>
              <th>Tax Amount</th>
            </tr>
          </thead>
          <tbody>
            {(ShiftBillTypeSummary?.dineIn?.data !== undefined && ShiftBillTypeSummary?.dineIn?.data.length!==0) ? (
              ShiftBillTypeSummary?.dineIn?.data?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.name}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.cusName}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.taxName}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amtTotal?.toFixed(decimalPosition)}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.taxAmount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
        style={{
          backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(1),
        }}
      >
        <h5>Take Away</h5>
        <h5>
          {(ShiftBillTypeSummary?.takeAway?.totalTax || 0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
              <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Order ID</th>
              <th>Customer</th>
              <th>Tax Name</th>
              <th>Order Amount</th>
              <th>Tax Amount</th>
            </tr>
          </thead>
         <tbody>
         {(ShiftBillTypeSummary?.takeAway?.data !== undefined && ShiftBillTypeSummary?.takeAway?.data.length!==0) ? (
           ShiftBillTypeSummary?.takeAway?.data?.map((r, i) => (
             <tr key={i}>
               <td
                 style={{ borderBottom: "1px solid black", height: "29px" }}
               >
                 {r.name}
               </td>
               <td
                 style={{ borderBottom: "1px solid black", height: "29px" }}
               >
                 {r.cusName}
               </td>
               <td
                 style={{ borderBottom: "1px solid black", height: "29px" }}
               >
                 {r.taxName}
               </td>
               <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amtTotal?.toFixed(decimalPosition)}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.taxAmount?.toFixed(decimalPosition)}
                  </td>
             </tr>
           ))
         ) : (
           <tr>
             <td colSpan={8}>NO DATA</td>
           </tr>
         )}
       </tbody>
       </table>
      )}
      <div
        className="bill-sub-head"
        style={{
          backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(2),
        }}
      >
        <h5>Delivery</h5>
        <h5>
          {(ShiftBillTypeSummary?.delivery?.totalTax || 0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Order ID</th>
              <th>Customer</th>
              <th>Tax Name</th>
              <th>Order Amount</th>
              <th>Tax Amount</th>
            </tr>
          </thead>
          <tbody>
            {(ShiftBillTypeSummary?.delivery?.data !== undefined && ShiftBillTypeSummary?.delivery?.data.length!==0) ? (
              ShiftBillTypeSummary?.delivery?.data?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.name}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.cusName}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.taxName}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amtTotal?.toFixed(decimalPosition)}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.taxAmount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default TaxDetailsSummary;
