import { IconButton, MenuItem, Select, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllDepartmentAPICall } from "../../../../../API/Staff/HRM/departmentAndDesignationAPI";
import { getAllActiveEmployeesAPICall } from "../../../../../API/Staff/HRM/employeeAPI";
import "../../../../../css/Staff/HRM/ManageEmployee/manageEmployee.css";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { convertDateFormat } from "../../../../../Js/Date";
import { Table } from "antd";
export const ManageEmployee = () => {
  
  const allDepartmentList = useSelector(
    (state) => state.allDepartmentSlice.value
  );
  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );

  const navigate = useNavigate();
  const [selectDpt, setSelectDpt] = useState("0");
  const [searchKeyword, setSearchKeyword] = useState("");
  //Loading State
  const [isLoading, setIsLoading] = useState(false);
  //Filtered Employee State
  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [errorSnackbar,setErrorSnackbar]=useState(false)
  const [snackMsg,setSnackMsg]=useState('Error!!')
  //Navigate to Employee Dashboard
  const employeeSingleView = (empId) => () => {
    localStorage.setItem("EMP_ID", empId);
    navigate("/userdashboard/staff/hrm/manageEmployee/viewEmployee");
  };
  const closeErrorSnackbar=()=>{
    setErrorSnackbar(false)
  }
  //Get search keyword
  const getSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  // search function
  const filterEmployeeBySearch=(chars,listToFilter) =>{
    let searchResult= [];
    let filteredArray= [];

    if(chars !== "") {
       searchResult =listToFilter?.filter(
          (obj) =>
          obj?.staff_name?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.emp_id?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.contactnumber?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.branchName?.toLowerCase()?.includes(chars?.toLowerCase()) 
       );
       filteredArray =searchResult;
    } else {
       filteredArray =listToFilter;
    }
    return filteredArray;
 }
  //Initial API Calls
  useEffect(() => {
    getAllDepartmentAPICall();
    getAllActiveEmployeesAPICall(
      setIsLoading,
      setErrorSnackbar,
      setSnackMsg
    );
  },[]);

  //Call Filter by dpt fn
  useEffect(() => {
    filterEmployeeByDpt();
  }, [selectDpt]);

  //assign emp response to state
  useEffect(() => {
    setFilteredEmployeeList(allActiveEmpList);
  }, [allActiveEmpList]);

  //filter function
  const filterEmployeeByDpt = () => {
    let filteredEmpList = [];
    if (selectDpt !== "0") {
      filteredEmpList = allActiveEmpList?.filter(
        (obj) => obj?.department === selectDpt
      );
      setFilteredEmployeeList(filteredEmpList);
    } else {
      if (allActiveEmpList !== undefined) {
        setFilteredEmployeeList(allActiveEmpList);
      }
    }
  };

  useEffect(()=>{
    if( filteredEmployeeList!==undefined &&filteredEmployeeList.length!==0){
      setSearchEmployeeList(filteredEmployeeList)
      let filteredArray=filterEmployeeBySearch(searchKeyword,filteredEmployeeList)
      setSearchEmployeeList(filteredArray)
    } else {
      setSearchEmployeeList([])
    }
  },[searchKeyword,filteredEmployeeList])

  const columns = [
    {
      title: 'No',
      width: 50,
      key: 'index',
      render: (text, record, index) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Emp ID',
      width: 80,
      key: 'emp_id',
      dataIndex: 'emp_id',
      fixed: 'left',
    },
    {
      title: 'Name',
      width: 100,
      key: 'staff_name',
      dataIndex: 'staff_name',
      fixed: 'left',
    },
    {
      title: 'Branch',
      width: 100,
      key: 'branchId',
      dataIndex: 'branchId',
    },
    {
      title: 'Image',
      width: 100,
      key: 'imageUrl',
      dataIndex: 'imageUrl',
      render: (imageUrl) => (
        <img
          src={imageUrl}
          alt="Employee"
          style={{ width: '40px', height: '40px', borderRadius: '50%' }}
        />
      ), 
    },
    {
      title: 'Contact',
      width: 100,
      key: 'contactNumber',
      dataIndex: 'contactnumber',
    },
    {
      title: 'Joining Date',
      width: 100,
      key: 'date_of_join',
      dataIndex: 'date_of_join',
      render: (date_of_join) => convertDateFormat(date_of_join),
    },
    {
      title: 'Department',
      width: 100,
      key: 'departmentName',
      dataIndex: 'departmentName',
    },
    {
      title: 'Designation',
      width: 100,
      key: 'designationName',
      dataIndex: 'designationName',
    },
    {
      title: 'Default Doc',
      width: 100,
      key: 'documents',
      dataIndex: 'documents',
      render:(documents)=>documents?documents[0]?.documentType:"-NIL-"
    },
    {
      title: 'Expiry Date',
      width: 100,
      key: 'docExpiry',
      dataIndex: 'documents',
      render:(documents)=>documents?convertDateFormat(documents[0]?.expiryDate):"-NIL-"
    },
    {
      title: 'Status',
      width: 100,
      key: 'status',
      dataIndex: 'status',
      textAlign: 'center',
      render:(status)=>status?<p className="manage-employee-table-active-emp">Active</p>:<p className="manage-employee-table-inactive-emp">Inactive</p>
    },
    {
      title: 'Actions',
      key: 'staff_name',
      fixed: 'right',
      width: 120,
      render: (_text, record) => <div><IconButton onClick={employeeSingleView(record?._id)}>
      <i class="bi bi-eye visibility-icon"></i>
    </IconButton>
 
    <IconButton onClick={employeeSingleView(record?._id)}>
      <i class="bi bi-pencil-square edit-icon"></i>
    </IconButton>
  
    <IconButton>
      <i class="bi bi-trash3 delete-icon"></i>
    </IconButton></div>,
    },
  ];
  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
          <h3>Employee List</h3>
          <div className="create-head-buttons-container manage-employee-top-container">
            <Select
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "#fff",
                },
              }}
              value={selectDpt}
              onChange={(e) => setSelectDpt(e.target.value)}
              style={{ margin: "0 10px" }}
            >
              <MenuItem value="0">All Department</MenuItem>
              {allDepartmentList?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.departmentName}
                </MenuItem>
              ))}
            </Select>
            <div className="view-order-search-container view-order-admin-search-container" >
              <input type="text" 
                placeholder="Employee name,id,mobile.."
                value={searchKeyword}
                onChange={getSearchKeyword}
              />
              <IconButton>
                <i style={{color:"grey"}} className="bi bi-search search-icon-order"></i>
              </IconButton>
            </div>
          </div>
      </div>

      <div className="updated-table-container antd-table-container" style={{ height: "calc( 100vh - 145px )" }}>
        <Table
          columns={columns}
          dataSource={searchEmployeeList}
          scroll={{
            x: 1500,
          }}
          sticky={{
            offsetHeader: 64,
          }}
          pagination={false}
          className="antd-table"
        />
      </div>

      <ErrorSnackbar
      open={errorSnackbar}
      handleClose={closeErrorSnackbar}
      message={snackMsg}
      />
    </div>
  );
};
