import React from 'react'
import AdvncShiftReportHeader from './AdvncShiftReportHeader'
import BillTypeSummaryPrint from './BillTypeSummaryPrint'
import DeliveryPartnerSummaryPrint from './DeliveryPartnerSummaryPrint'
import DeliveryBoysSummaryPrint from './DeliveryBoysSummaryPrint'
import DiscountSummaryPrint from './DiscountSummaryPrint'
import CategorySalesSummaryPrint from './CategorySalesSummaryPrint'
import TaxDetailsPrint from './TaxDetailsPrint'
import StarHorizontalLine from '../../../../Single Components/StarHorizontalLine'

const AdvanceSummaryFullPrint = () => {
  return (
    <div className='flex-column advance-shift-report-table-container'>
        <AdvncShiftReportHeader/>
            <BillTypeSummaryPrint/>
            <StarHorizontalLine/>
            <DeliveryPartnerSummaryPrint/>
            <StarHorizontalLine/>
            <DeliveryBoysSummaryPrint/>
            <StarHorizontalLine/>
            <DiscountSummaryPrint/>
            <StarHorizontalLine/>
            <TaxDetailsPrint/>
            <StarHorizontalLine/>
            <CategorySalesSummaryPrint/>
            <StarHorizontalLine/>
    </div>
    

  )
}

export default AdvanceSummaryFullPrint