import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

const PaymentSummary = () => {
    const paymentSummmary = useSelector(
        (state) => state.posReportshiftSlice.shiftReporPaymentSummary);
const decimalPosition = localStorage.getItem("decimalPosition");
  
        
  return (
    <div className="new-global-table-container" style={{ paddingTop: "0 42px" }}>
    <table>
        <thead>
            <tr>
                <th>Payment Method</th>
                <th> Amount</th>
            </tr>
        </thead>
        <tbody>
            {paymentSummmary?.sale!==undefined ? paymentSummmary?.sale?.map((r, i) => (
                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                    
                    <td style={{ border: "none", height: "29px" }}>{r.type}</td>
                  
                    <td style={{ border: "none", height: "29px" }}>{r.amount}</td>
                    
                </tr>
            )):(
                <tr>
                    <td colSpan={2}>NO DATA</td>
                </tr>
            )}
        </tbody>
        <tfoot>
        <tr style={{backgroundColor:"rgb(150 148 148)"}}>
           <td style={{textAlignLast:"end",paddingInline:"10px"}}>TOTAL</td> 
           <td style={{textAlignLast:"center"}}>{paymentSummmary?.sale?.reduce((acc, current) => acc + Number(current?.amount || 0), 0)?.toFixed(decimalPosition)}</td>
        </tr>
    </tfoot>
    </table>
    </div>
  )
}

export default PaymentSummary