import React from 'react'
import { useSelector } from 'react-redux';

const TaxDetailsPrint = () => {

    const decimalPosition = localStorage.getItem("decimalPosition");
    const ShiftBillTypeSummary = useSelector((state) => state.posReportshiftSlice.taxDetails);
    const {showSubHeaders} = useSelector((state) => state.posReportshiftSlice);

  return (
     <div
      className="new-global-table-container shift-report-print-container">
      <div className="bill-main-head">
        <h5>Bill Type</h5>
        <h5>Tax Amount</h5>
      </div>
      <div
        className="bill-sub-head">
        <h5>Dine In</h5>
        <h5>
          {(ShiftBillTypeSummary?.dineIn?.totalTax || 0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead>
            <tr>
              <th>O/ID</th>
              <th>Tax</th>
              <th>O Amt</th>
              <th>Tax Amt</th>
            </tr>
          </thead>
          <tbody>
            {(ShiftBillTypeSummary?.dineIn?.data !== undefined && ShiftBillTypeSummary?.dineIn?.data.length!==0) ? (
              ShiftBillTypeSummary?.dineIn?.data?.map((r, i) => (
                <tr key={i}>
                  <td>
                    {r?.name}
                  </td>
                  <td>
                    {r.taxName}
                  </td>
                  <td>
                    {r?.amtTotal?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {r?.taxAmount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div className="bill-sub-head">
        <h5>Take Away</h5>
        <h5>
          {(ShiftBillTypeSummary?.takeAway?.totalTax || 0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
              <thead>
            <tr>
              <th>O/ID</th>
              <th>Tax</th>
              <th>O Amt</th>
              <th>Tax Amt</th>
            </tr>
          </thead>
         <tbody>
         {(ShiftBillTypeSummary?.takeAway?.data !== undefined && ShiftBillTypeSummary?.takeAway?.data.length!==0) ? (
           ShiftBillTypeSummary?.takeAway?.data?.map((r, i) => (
             <tr key={i}>
               <td>
                 {r.name}
               </td> 
               <td>
                 {r.taxName}
               </td>
               <td>
                    {r.amtTotal?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {r.taxAmount?.toFixed(decimalPosition)}
                  </td>
             </tr>
           ))
         ) : (
           <tr>
             <td colSpan={8}>NO DATA</td>
           </tr>
         )}
       </tbody>
       </table>
      )}
      <div className="bill-sub-head">
        <h5>Delivery</h5>
        <h5>
          {(ShiftBillTypeSummary?.delivery?.totalTax || 0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead>
            <tr>
              <th>O/ID</th>
              <th>Tax</th>
              <th>O Amt</th>
              <th>Tax Amt</th>
            </tr>
          </thead>
          <tbody>
            {(ShiftBillTypeSummary?.delivery?.data !== undefined && ShiftBillTypeSummary?.delivery?.data.length!==0) ? (
              ShiftBillTypeSummary?.delivery?.data?.map((r, i) => (
                <tr key={i}>
                  <td>
                    {r?.name}
                  </td>
                  <td>
                    {r?.taxName}
                  </td>
                  <td>
                    {r?.amtTotal?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {r?.taxAmount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default TaxDetailsPrint