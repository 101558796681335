import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Stack,
  Chip,
  Avatar,
  Select,
  MenuItem,
  Tooltip,
  Dialog,
  TextField,
  Autocomplete,
} from "@mui/material";
import "../../css/Dashboard/dashboard.css";
import { Route, Routes, useNavigate,Navigate } from "react-router-dom";
import { ProductList } from "../Modules/Product List/Pages/ProductList";
import { logOutUser } from "../../Js/generalFunctions";
import { useDispatch, useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Settings } from "../Modules/Settings/Settings";

import { Department } from "../Modules/Staff/HRM/Department";
import { Designation } from "../Modules/Staff/HRM/Designation";
import { AddEmployee } from "../Modules/Staff/HRM/AddEmployee/AddEmployee";
import { ManageEmployee } from "../Modules/Staff/HRM/ManageEmployee/ManageEmployee";
import { ViewEmployee } from "../Modules/Staff/HRM/ManageEmployee/ViewEmployee";
import { AddDocument } from "../Modules/Staff/HRM/AddDocument";

import { StockReport } from "../Modules/Report/StockReport/StockReport";
import { StockAdjustmentReport } from "../Modules/Report/StockAdjustmentReport/StockAdjustmentReport";
import { ExpenseReport } from "../Modules/Report/ExpenseReport/ExpenseReport";
import { StockSummaryReport } from "../Modules/Report/StockSummaryReport/StockSummaryReport";

import { useEffect } from "react";
import { StaffExpense } from "../Modules/Expense/StaffExpense";
import { OutletExpense } from "../Modules/Expense/OutletExpense";
import { PettyCashReceipt } from "../Modules/Expense/PettyCashReceipt";
import { AddExpenseType } from "../Modules/Expense/AddExpenseType";
import { useState, useRef } from "react";
import { GenerateQrOrBarcode } from "../Modules/GenerateQrOrBarcode/GenerateQrOrBarcode";
import {
  startManualShiftAPICall,
  endManualShiftAPICall,
  addShiftDenominationAPICall,
} from "../../API/Settings/Shift/manualShiftAPI";
import {
  getCurrentTime,
  getTimer,
  today,
} from "../../Js/Date";
import ErrorSnackbar from "../Single Components/SnackBars/ErrorSnackbar";

import {
  verifyPasswordForShiftAPICall,
} from "../../API/Login/VerifyPasswordAPI";
import { getShiftStatusAPICAll } from "../../API/Settings/Shift/viewShiftAPI";
import { StaffDashboard } from "../Modules/Staff/DashBoard/StaffDashboard";
import { StaffAttendance } from "../Modules/Staff/Attendance/StaffAttendance";
import { StaffLeaveApplication } from "../Modules/Staff/Leave/StaffLeaveApplication";
import { StaffHoliday } from "../Modules/Staff/Leave/StaffHoliday";
import { StaffAddLeaveType } from "../Modules/Staff/Leave/StaffAddLeaveType";
import { StaffPayrollEmpSalary } from "../Modules/Staff/Payroll/StaffPayrollEmpSalary";
import { StaffPayrollAddPayroll } from "../Modules/Staff/Payroll/StaffPayrollAddPayroll";
import { StaffPayrollItem } from "../Modules/Staff/Payroll/StaffPayrollItem";
import { EmpDashboardTotalCommission } from "../Modules/Staff/HRM/ManageEmployee/Tabs/EmpDashboardTotalCommission";
import { StaffAddNewLoan } from "../Modules/Staff/Loan/StaffAddNewLoan";
import { StaffLoanReport } from "../Modules/Staff/Loan/StaffLoanReport";
import { StaffLoanApprovals } from "../Modules/Staff/Loan/StaffLoanApprovals";
import { DocumentPrintView } from "../Modules/Staff/HRM/DocumentPrintView";
import { StaffPaySlip } from "../Modules/Staff/Payroll/StaffPaySlip";
import { DeviceSettings } from "../Modules/Settings/Device Settings/DeviceSettings";

import { Journal } from "../Modules/Accounts/Accounting/Journal";
import { JournalEntries } from "../Modules/Accounts/Accounting/JournalEntries";
import { ReccurringPosting } from "../Modules/Accounts/Accounting/ReccurringPosting";
import { ChartOfAccounts } from "../Modules/Accounts/Accounting/ChartOfAccounts";

import { JournalEntriesForm } from "../Modules/Accounts/Accounting/JournalEntriesForm";

import { CreateJournal } from "../Modules/Accounts/Accounting/CreateJournal";
import { Configuration } from "../Modules/Accounts/Configuration/Configuration";
import { CreateChartOfAccount } from "../Modules/Accounts/Accounting/CreateChartofAccount";
import { UnitOfMeasurment } from "../Modules/Purchase Manager/Pages/UnitOfMeasurment/UnitOfMeasurment";
import { NewUOM } from "../Modules/Purchase Manager/Pages/UnitOfMeasurment/NewUOM";
import { ConfigurationPurchase } from "../Modules/Purchase Manager/Pages/Configuration/ConfigurationPurchase";

import { CreateVendorBills1 } from "../Modules/Accounts/Vendor/pages/CreateVendorBills1";
import { VendorPayments } from "../Modules/Accounts/Vendor/pages/VendorPayments";
import { DebitNotes } from "../Modules/Accounts/Vendor/pages/DebitNotes";
import { Vendors } from "../Modules/Accounts/Vendor/pages/Vendors";

import { CreateVendorPayments } from "../Modules/Accounts/Vendor/pages/CreateVendorPayments";
import { CreateVendors } from "../Modules/Accounts/Vendor/pages/CreateVendors";

import { WareHouse } from "../Modules/Inventory/Configuration/WareHouse";
import { InventoryLocation } from "../Modules/Inventory/Configuration/InventoryLocation";
import { CreateWareHouse } from "../Modules/Inventory/Configuration/CreateWareHouse";
import { CreateInvLocation } from "../Modules/Inventory/Configuration/CreateInvLocation";

import { InternalTransfer } from "../Modules/Inventory/Operations/InternalTransfer/InternalTransfer";
import { BranchTransfer } from "../Modules/Inventory/Operations/BranchTransfer/BranchTransfer";

import { InventoryAdjustment } from "../Modules/Inventory/Operations/InventoryAdjustment/InventoryAdjustment";
import { LandedCost } from "../Modules/Inventory/Operations/LandedCost/LandedCost";

import { StockMoves } from "../Modules/Inventory/Operations/Stock Moves/StockMoves";
import { CreateInternalTransfer } from "../Modules/Inventory/Operations/InternalTransfer/CreateInternalTransfer";
import { CreateBranchTransfer } from "../Modules/Inventory/Operations/BranchTransfer/CreateBranchTransfer";
import { RequestForQuatation } from "../Modules/Purchase Manager/Pages/RequestForQuatation";
import { NewPurchaseOrder } from "../Modules/Purchase Manager/Pages/NewPurchaseOrder";
import { NewGoodsReceivedNotes1 } from "../Modules/Purchase Manager/Pages/NewGoodsReceivedNotes1";

import GoodsReceivedNotesSingleView from "../Modules/Purchase Manager/Pages/GRNUpdated/GoodsReceivedNotesSingleView";
import { VendorListPurchaseManage } from "../Modules/Purchase Manager/Pages/Vendor/VendorListPurchaseManage";
import { SalesOrder } from "../Modules/SalesNew/SalesOrders/SalesOrder/SalesOrder";
import { SalesQuotation } from "../Modules/SalesNew/SalesOrders/SalesQuotation/SalesQuotation";
import { CreateSalesQuotation } from "../Modules/SalesNew/SalesOrders/SalesQuotation/CreateSalesQuotation";
import { CreateSalesOrder } from "../Modules/SalesNew/SalesOrders/SalesOrder/CreateSalesOrder";
import { DeliveryNote } from "../Modules/SalesNew/SalesOrders/DeliveryNote/DeliveryNote";
import { CreateDeliiveryNote } from "../Modules/SalesNew/SalesOrders/DeliveryNote/CreateDeliiveryNote";
import { CustomerInvoice } from "../Modules/Accounts/Customers/CustomerInvoice/CustomerInvoice";
import { CustomerPayments } from "../Modules/Accounts/Customers/Payments/CustomerPayments";
import { CustomerCreditNotes } from "../Modules/Accounts/Customers/CreditNotes/CustomerCreditNotes";
import { Customers } from "../Modules/Accounts/Customers/Customers/Customers";
import { CreateCustomerInvoice } from "../Modules/Accounts/Customers/CustomerInvoice/CreateCustomerInvoice";
import { CreateCustomerPayment } from "../Modules/Accounts/Customers/Payments/CreateCustomerPayment";
import { CreateCustomerCreditNotes } from "../Modules/Accounts/Customers/CreditNotes/CreateCustomerCreditNotes";
import { CreateDebitNote } from "../Modules/Accounts/Vendor/pages/CreateDebitNote";
import { CreateCustomer } from "../Modules/Accounts/Customers/Customers/CreateCustomer";
import { SettingsProductVariant } from "../Modules/Inventory/Configuration/SettingsProductVariant";
import { Attribute } from "../Modules/Inventory/Configuration/Attribute/Attribute";
import { CreateAttribute } from "../Modules/Inventory/Configuration/Attribute/CreateAttribute";
import { POSCategory } from "../Modules/Inventory/Configuration/POSCategory/POSCategory";
import { CreatePOSCategory } from "../Modules/Inventory/Configuration/POSCategory/CreatePOSCategory";
import { Category } from "../Modules/Inventory/Configuration/Category/Category";
import { CreateCategory } from "../Modules/Inventory/Configuration/Category/CreateCategory";
import { CreateInventoryAdjustment } from "../Modules/Inventory/Operations/InventoryAdjustment/CreateInventoryAdjustment";
import { SalesWSO } from "../Modules/Accounts/Customers/SalesWSO/SalesWSO";
import { CreateSalesWSO } from "../Modules/Accounts/Customers/SalesWSO/CreateSalesWSO";
import { BranchReceipt } from "../Modules/Inventory/Operations/BranchReceipt/BranchReceipt";
import { CreateBranchReceipt } from "../Modules/Inventory/Operations/BranchReceipt/CreateBranchReceipt";
import { ProductListUpdated } from "../Modules/Inventory/Products/ProductListUpdated/ProductListUpdated";
import { CreateProductList } from "../Modules/Inventory/Products/ProductListUpdated/CreateProductList";
import { ProductMasterUpdated } from "../Modules/Inventory/Products/ProductMasterUpdated/ProductMasterUpdated";
import { CreateProductMaster } from "../Modules/Inventory/Products/ProductMasterUpdated/CreateProductMaster";
import { CreateRequestForQuotation1 } from "../Modules/Purchase Manager/Pages/CreateRequestForQuotation1";
import { CreateNewPurchaseOrder1 } from "../Modules/Purchase Manager/Pages/CreateNewPurchaseOrder1";
import { GrnListing1 } from "../Modules/Purchase Manager/Pages/GRNUpdated/GrnListing1";

import { VendorBills1 } from "../Modules/Accounts/Vendor/pages/VendorBills1";
import { PurchaseWOPO1 } from "../Modules/Accounts/Vendor/purchaseWPO/PurchaseWOPO1";

import { BillingNew } from "../Modules/Sales/Billing/BillingNew";
import { CreatePurchaseWOPO1 } from "../Modules/Accounts/Vendor/purchaseWPO/CreatePurchaseWOPO1";

import CreateLandedCost from "../Modules/Inventory/Operations/LandedCost/CreateLandedCost";

import { RequestForQuatationSingleView } from "../Modules/Purchase Manager/Pages/RequestForQuatationSingleView";

import { PosSettings } from "../Modules/PointOfSale/Configuration/Settings/PosSettings";
import { BranchSettings } from "../Modules/PointOfSale/Configuration/BranchSettings/BranchSettings";
import { BranchSettingsForm } from "../Modules/PointOfSale/Configuration/BranchSettings/BranchSettingsForm";
import { SalesQuatationSingleVieww } from "../Modules/SalesNew/SalesOrders/SalesQuotation/SalesQuatationSingleVieww";
import { DeliverNoteSingleView } from "../Modules/SalesNew/SalesOrders/DeliveryNote/DeliverNoteSingleView";
import { SalesCustomers } from "../Modules/SalesNew/SalesOrders/SalesCustomers/SalesCustomers";

import { viewPosSettingsAPICall } from "../Modules/PointOfSale/Configuration/Settings/posSettingsAPI";
import { getAllActiveEmployeesAPICall } from "../../API/Staff/HRM/employeeAPI";

import { SalesWSOsingleView } from "../Modules/Accounts/Customers/SalesWSO/SalesWSOsingleView";
import { DebitNoteSingleView } from "../Modules/Accounts/Vendor/pages/DebitNoteSingleView";
import { VendorBillSingleView } from "../Modules/Accounts/Vendor/pages/VendorBillSingleView";
import { PurchaseWOPOsingleView } from "../Modules/Accounts/Vendor/purchaseWPO/PurchaseWOPOsingleView";
import CreatePriceList from "../Modules/SalesNew/SalesOrders/priceList/CreatePriceList";
import PriceList from "../Modules/SalesNew/SalesOrders/priceList/PriceList";
import { SalesOrderSingleView } from "../Modules/SalesNew/SalesOrders/SalesOrder/SalesOrderSingleView";
import { PurchaseOrderSingleView } from "../Modules/Purchase Manager/Pages/PurchaseOrderSingleView";
import { CreaditNoteSingleView } from "../Modules/Accounts/Customers/CreditNotes/CreaditNoteSingleView";
import { CustomerInvoiceSingleView } from "../Modules/Accounts/Customers/CustomerInvoice/CustomerInvoiceSingleView";
import PosOrdersSingleView from "../Modules/PointOfSale/General/Order/PosOrdersSingleView";
import {
  addCashInOrOutAPICall,
  viewCashInOrCashOutAPICall,
} from "../../API/Dashboards/mainDashboardAPI";
import SuccessSnackbar from "../Single Components/SnackBars/SuccessSnackbar";

import { TrialBalance } from "../Modules/Accounts/ReportsNew/FinancialReports/TrialBalance";

import { viewGlConfigAPICall } from "../Modules/Accounts/Configuration/glConfigAPI";
import { viewAllCurrencyUpdated } from "../../API/Settings/Currency/CurrencyAPI";

import { LedgerBalance } from "../Modules/Accounts/ReportsNew/FinancialReports/LedgerBalance";
import { GeneralLedgerNw } from "../Modules/Accounts/ReportsNew/FinancialReports/GeneralLedgerNw";
import { ProfitAndLoss } from "../Modules/Accounts/ReportsNew/FinancialReports/ProfitAndLoss";
import { SalesNewReport } from "../Modules/SalesNew/SalesReport/SalesNewReport";
import { AccountPayable } from "../Modules/Accounts/ReportsNew/PartnerReport/AccountPayable";
import AccountPayableSingleView from "../Modules/Accounts/ReportsNew/PartnerReport/AccountPayableSingleView";
import AccountReceivableAgingReport from "../Modules/Accounts/ReportsNew/PartnerReport/AccountReceivableAgingReport";
import { AccountRecievable } from "../Modules/Accounts/ReportsNew/PartnerReport/AccountRecievable";
import { BankAndCashReport } from "../Modules/Accounts/ReportsNew/General Report/BankAndCashReport";
import BankAndCashReportSingleView from "../Modules/Accounts/ReportsNew/General Report/BankAndCashReportSingleView";
import { AccountRecievableSingleView } from "../Modules/Accounts/ReportsNew/PartnerReport/AccountRecievableSingleView";
import { SalesReportSingleView } from "../Modules/SalesNew/SalesReport/SalesReportSingleView";
import BalanceSheet from "../Modules/Accounts/ReportsNew/FinancialReports/BalanceSheet";
import { StockMove } from "../Modules/Inventory/Reports/StockMove";
import { StockMoveSingleView } from "../Modules/Inventory/Reports/StockMoveSingleView";
import PurchaseReportNew from "../Modules/Purchase Manager/Pages/PurchaseReport.jsx/PurchaseReportNew";
import { PurchaseReportSingleView } from "../Modules/Purchase Manager/Pages/PurchaseReport.jsx/PurchaseReportSingleView";

import { BankReconciliationNew } from "../Modules/Accounts/Reconciliation/BankReconciliationNew";
import { CreateBankReconciliation } from "../Modules/Accounts/Reconciliation/CreateBankReconciliation";

import { PosSalesReport } from "../Modules/Report/POSSalesReport/PosSalesReport";
import EstablisId from "../Modules/Payroll/Establishment/EstablisId";
import InputOrOutputReport from "../Modules/Accounts/VatReport/inputOrOutputReport/InputOrOutputReport";

import { getAllNewProductList } from "../Modules/Accounts/Vendor/API/vendorBillAPI";
import { vendorListApi } from "../Modules/Accounts/Vendor/API/vendorListAPICall";
import { customerListApi } from "../../API/Customer/customerAPI";

import OvertimeList from "../Modules/Payroll/Attendance/Overtime/OvertimeList";
import AccountReceivableAgingReportSingleView from "../Modules/Accounts/ReportsNew/PartnerReport/AccountReceivableAgingReportSingleView";
import AccountPayableAgingReport from "../Modules/Accounts/ReportsNew/PartnerReport/AccountPayableAgingReport";
import AccountPayableAgingReportSingleView from "../Modules/Accounts/ReportsNew/PartnerReport/AccountPayableAgingReportSingleView";
import PayHead from "../Modules/Payroll/configuration/payHead/PayHead";
import { QrcodeScanView } from "../Modules/Sales/Billing/QrcodeScanView";
import { ProductListSingleView } from "../Modules/Product List/Pages/ProductListSingleView";
import AddSeat from "../Modules/FloorManagement/addSeat/AddSeat";
import { switch_to_billing_screen } from "../Modules/Sales/Billing/newBillingSlice";
import KitchenOrderTicket from "../Modules/kitchenOrderTicket/KitchenOrderTicket";
import KotTokenDisplay from "../Modules/KotTokenDisplay/KotTokenDisplay";
import SpecialItems from "../Modules/Sales/SpecialItems/SpecialItems";
import CreateSpecialItems from "../Modules/Sales/SpecialItems/CreateSpecialItems";
import {
  setErrorSnack,
  setSnackMsg,
  setSuccessSnack,
} from "../Modules/Sales/Billing/Slice/billingScreenSlice";
import { LoadingForm } from "../Single Components/LoadingForm";
import TestPage from "../Modules/components/testpage/TestPage";
import CustomerDisplay from "../Modules/customerDisplay/CustomerDisplay";
import { ConfigurationFood } from "../Modules/FoodManagementCopy/ConfigurationFood";
import RecipeFood from "../Modules/FoodManagementCopy/RecipeFood";
import Aggregator from "../Modules/PointOfSale/Configuration/Aggregator/Aggregator";
import { companyProfileInfoForUser, viewAllBranchesAPICall } from "../../API/Settings/Company Settings/companySettingsAPI";

import PosShift from "../Modules/PointOfSale/General/Shift/PosShift";

import RecipeCostAnalysis from "../Modules/FoodManagementCopy/RecipeCostAnalysis";
import RecipeFoodList from "../Modules/FoodManagementCopy/RecipeFoodList";

import PosReaderPreview from "../Modules/PointOfSale/posReaderPreview/PosReaderPreview";

import PosShiftSingleView from "../Modules/PointOfSale/General/Shift/PosShiftSingleView";
import ManufacturingOrder from "../Modules/FoodManagementCopy/ManufacturingOrder";
import ManufacturingOrderList from "../Modules/FoodManagementCopy/ManufacturingOrderList";
import ManufacturingCostAnalysis from "../Modules/FoodManagementCopy/ManufacturingCostAnalysis";
import PosOrders from "../Modules/PointOfSale/General/Order/PosOrders";
import PosPayment from "../Modules/PointOfSale/General/payment/PosPayment";
import PosWallet from "../Modules/PointOfSale/General/wallet/PosWallet";
import PosShiftReport from "../Modules/PointOfSale/Report/PosShiftReport";
import PosShiftSummaryReport from "../Modules/PointOfSale/Report/PosShiftSummaryReport";
import PosSalesDetails from "../Modules/PointOfSale/Report/PosSalesDetails";

import AccountDashboard from "../Modules/Accounts/Dashboard/AccountDashboard";

import { setBranchDetails, setCurrencyDetails, setEmployeeDetails } from "../../Redux/Dashboard/mainDashBoardSlice";

import ReceivableDueReport from "../Modules/Accounts/ReportsNew/PartnerReport/ReceivableDueReport";
import ReceivableDueReportSingleView from "../Modules/Accounts/ReportsNew/PartnerReport/ReceivableDueReportSingleView";
import PyableDueReport from "../Modules/Accounts/ReportsNew/PartnerReport/PyableDueReport";
import PayableDueReportSingleView from "../Modules/Accounts/ReportsNew/PartnerReport/PayableDueReportSingleView";
import InvoiceMarginReport from "../Modules/Accounts/ReportsNew/General Report/InvoiceMarginReport";
import InvoiceMarginReportSingleView from "../Modules/Accounts/ReportsNew/General Report/InvoiceMarginReportSingleView";
import ProductMarginReport from "../Modules/Accounts/ReportsNew/General Report/ProductMarginReport";
import ProductMarginReportSingleView from "../Modules/Accounts/ReportsNew/General Report/ProductMarginReportSingleView";

import HomeDashboardNew from "../Modules/HomeDashboard/homeDashboardNew/HomeDashboard"; 


import CustomerReceiptReport from "../Modules/Accounts/ReportsNew/General Report/CustomerReceiptReport";
import CustomerReceiptReportSingleView from "../Modules/Accounts/ReportsNew/General Report/CustomerReceiptReportSingleView";
import SalesReportBySalesPerson from "../Modules/SalesNew/SalesReport/SalesReportBySalesPerson";
import SalesReportBySalesSingleView from "../Modules/SalesNew/SalesReport/SalesReportBySalesSingleView";
import OpeningBalance from "../Modules/Accounts/Accounting/OpeningBalance";
import CreateOpeningBalance from "../Modules/Accounts/Accounting/CreateOpeningBalance";

import PrintDemo from "../Modules/PrintDemo";
import DailyCashCardSummary from "../Modules/PointOfSale/Report/DailyCashCardSummary";
import PosAggregatorReport from "../Modules/PointOfSale/Report/PosAggregatorReport";
import ChequeRegisterReport from "../Modules/Accounts/ReportsNew/General Report/ChequeRegisterReport";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SideMenuUpdated from "./SideMenuUpdated";
import CustomeKeyBoard from "../Single Components/CustomeKeyBoard";
import { viewInventoryConfigSettingsAPICall } from "../Modules/Inventory/Configuration/inventoryConfigurationSettingsAPI";
import { employeeRolesAndPermission } from "../../API/Settings/Roles and permission/rolesAndPermissionAPI";
import { AccessDenied } from "./AccessDenied";
import CashInOrOut from "../Single Components/CashInOrOut/CashInOrOut";
import { setCashInOrOutDialog } from "../../Redux/Dashboard/cashInOrOutSlice";

import PosNewShiftReport from "../Modules/PointOfSale/Report/PosNewShiftReport";
import DeliveryScreen from "../Modules/PointOfSale/DeliveryScreen/DeliveryScreen";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { set_shift_report_branch, set_shift_report_shift, set_shift_report_submit } from "../Modules/PointOfSale/Report/ReportSlice";
import ShiftReportTable from "../Modules/PointOfSale/Report/PosShiftReportTables/ShiftReportTable";
import ShiftTimerComponent from "../Single Components/ShiftTimerComponent";
const drawerWidth = 290;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function ProtectedRoute({ element: Element, isAuthenticated, fallbackPath ,...rest}) {
  // console.log("isAuthenticated",isAuthenticated);
  return isAuthenticated ? (
    <Element {...rest}/>
  ) : (
    <Navigate to={fallbackPath} />
  );
}

function DashboardContent() {
  const billingScreen = useSelector(
    (state) => state.newBillingSlice.billingScreen
  );
  
  const permission = JSON.parse(localStorage.getItem("userpermission"))
  // console.log(permission);
  const dispatch = useDispatch();
  //Login Response From Redux
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  // pos settings
  const { submitLoading } = useSelector((state) => state.mainDashBoardSlice);
  const posSettingsList = useSelector(
    (state) => state.posSettingSlice.apiResList
  );

  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const { successSnack, errorSnack, snackMsg } = useSelector(
    (state) => state.billingScreenSlice
  );
  const { hideHeader } = useSelector(
    (state) => state.kotTokenDisplaySlice
  );
  const [branchName, setBranchName] = useState("...");
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const userLoginMeta = localStorage.getItem("login_meta");
  const userBranchId = JSON.parse(userLoginMeta)?.branchId;
  const userBranchpk = JSON.parse(userLoginMeta)?.branchPk;
  const employeeId =JSON.parse(userLoginMeta)?.data?._id
  const userShiftStatus = localStorage.getItem("shiftStatus");
  const userShiftId = JSON.parse(userShiftStatus)?.shiftId;
  const userShiftCode = JSON.parse(userShiftStatus)?._id;
  const storeCode = localStorage.getItem("branchId");

  // let cashInOutPrint = useRef()

  // shift button change state
  const [shiftBtnState, setShiftBtnState] = useState(false);
  // shift end password dialog state
  const [shiftEndPasswordDialog, setShiftEndPasswordDialog] = useState(false);
  
  // snackbar state
  const [errorSnackBar, setErrorSnackBar] = useState(false);
  const [sucessSnackBar, setSuccessSnackBar] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error!!");
  
  const [timer, setTimer] = useState(0);
  const [verifyPassWord, setVerifyPassword] = useState("");
  
  
  const [shiftReoprtOpen, setShiftReportBtn] = useState(false);
  // denomination dialog
  const [denominationDialog, setDenominationDialog] = useState(false);

  const increment = useRef(null);
  let date = new Date();


  const [shiftDenominationTable, setShiftDenominationTable] = useState([]);
  const [denominationGrandTotal, setShiftDenominationGrandTotal] = useState("");
  const shiftDenominationBodyInitialState = {
    currency: "Rs",
    amount: "",
    count: "",
    total: "0.00",
    grandTotal: "",
  };

  const [shiftDenominationBody, setShiftDenominationBody] = useState(
    shiftDenominationBodyInitialState
  );

  const [fullScreenEnable,setEnableFullScreen]=useState(false)
  const shiftDenominationGetForm = (key) => (e) => {
    const { value } = e.target;
    if (key === "currency") {
      setShiftDenominationBody({ ...shiftDenominationBody, currency: value });
    }
    if (key === "amount") {
      setShiftDenominationBody({
        ...shiftDenominationBody,
        amount: value,
        total: shiftDenominationBody.count * value,
      });
    }
    if (key === "count") {
      setShiftDenominationBody({
        ...shiftDenominationBody,
        count: value,
        total: shiftDenominationBody.amount * value,
      });
    }
  };



  const shiftStatus = useSelector((state) => state.shiftStatusSlice.value);


  const closeSnackbar = () => {
    dispatch(setErrorSnack(false));
    dispatch(setSuccessSnack(false));

    setTimeout(() => {
      dispatch(setSnackMsg(""));
    }, 1000);
  };

  // const formatTime = () => {
  //   // const getSeconds = `0${timer % 60}`.slice(-2);
  //   // const minutes = `${Math.floor(timer / 60)}`;
  //   // const getMinutes = `0${minutes % 60}`.slice(-2);
  //   // const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
  //   // return `${getHours} : ${getMinutes} : ${getSeconds}`;
  // };
  const handleStart = () => {
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(increment.current);
  };
  const handleReset = () => {
    clearInterval(increment.current);
    setTimer(0);
  };

  const handleResume = () => {
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  // shift button change function
  const shiftStartBtnFn = () => {
    startManualShiftAPICall(
      {
        branchId: storeCode,
        startDate: `${today} ${getCurrentTime()}`,
      },
      setShiftBtnState,
      shiftBtnState,
      handleReset,
      handleStart,
      setErrorSnackBar,
      setErrorMsg
    );
  };

  const shiftEndBtnClick = () => {
    // setShiftBtnState(!shiftBtnState)
    if (shiftStatus !== null) {
      shiftStatus.isDenomination
        ? setDenominationDialog(true)
        : setShiftEndPasswordDialog(true);
    }
  };
  const shiftEndPasswordSubmitBtnClick = () => {
    endManualShiftAPICall(
      {
        branchId: userBranchpk,
        endDate: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")} ${String(
          date.getHours()
        ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
          2,
          "0"
        )}:${String(date.getSeconds()).padStart(2, "0")}`,
      },
      handlePause,
      setShiftEndPasswordDialog,
      setShiftBtnState,
      shiftBtnState,
      setErrorSnackBar,
      setErrorMsg
    );
    localStorage.setItem("shitBtnState", JSON.stringify(!shiftBtnState));
    localStorage.removeItem("timer");
  };

  // Verify Password Function (especially for close shift)
  const verifyUserPassword = () => {
    if (verifyPassWord !== "") {
      verifyPasswordForShiftAPICall(
        { password: verifyPassWord },
        setErrorSnackBar,
        setErrorMsg,
        shiftEndPasswordSubmitBtnClick
      );
    }
  };
  // snackbar function
  const errorSnackbarClose = () => {
    setErrorSnackBar(false);
  };
  const successSnackbarClose = () => {
    setSuccessSnackBar(false);
  };
  const clickAddSingleDenomination = () => {
    if (shiftDenominationBody.amount === "") {
      console.log("alert");
    } else if (shiftDenominationBody.count === "") {
      console.log("alert");
    } else {
      let singleObj = {
        currency: shiftDenominationBody.currency,
        amount: shiftDenominationBody.amount,
        count: shiftDenominationBody.count,
        total: shiftDenominationBody.total,
      };
      setShiftDenominationTable([...shiftDenominationTable, singleObj]);
      setShiftDenominationBody(shiftDenominationBodyInitialState);
    }
  };
  const clickDelSingleDenomination = (index) => () => {
    let duplicateArray = [...shiftDenominationTable];
    duplicateArray.splice(index, 1);
    setShiftDenominationTable(duplicateArray);
  };
  const clickEditSingleDenomination = (index) => () => {
    let duplicateArray = [...shiftDenominationTable];
    setShiftDenominationBody({
      ...shiftDenominationBody,
      currency: duplicateArray[index].currency,
      amount: duplicateArray[index].amount,
      count: duplicateArray[index].count,
      total: duplicateArray[index].total,
    });
    duplicateArray.splice(index, 1);
    setShiftDenominationTable(duplicateArray);
  };
  const denominationUpdated = () => {
    setDenominationDialog(false);
    setShiftDenominationTable([]);
    setShiftEndPasswordDialog(true);
  };
  const shiftAddDenominationPayload = {
    denomination: shiftDenominationTable,
    branchId: userBranchId,
    shiftId: userShiftId,
    date: today,
  };
  const denominationSubmitBtn = () => {
    if (shiftDenominationTable.length !== 0) {
      addShiftDenominationAPICall(
        shiftAddDenominationPayload,
        denominationUpdated
      );
    }
    // setShiftEndPasswordDialog(true);
  };

  const [executeRequiringDialog, setExecuteRecurringDialog] = useState();
  const navigate = useNavigate();
  const showExecuteRecurringPostDialog = () => {
    // add data from localstorage
  const showExecuteRecurring = localStorage.getItem("accountExecute");
    if (showExecuteRecurring) {
      setExecuteRecurringDialog(true);
    } else {
      setExecuteRecurringDialog(false);
    }
  };

  const handleFullscreen = () => {


    if(fullScreenEnable){
        setEnableFullScreen(false)
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }
    }else{
      setEnableFullScreen(true)
      const elem = document.documentElement; // Select the entire document
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { // IE/Edge
        elem.msRequestFullscreen();
      }
    }
    
    };

    const shiftSummaryReportClick=()=>{
      setShiftReportBtn(true)
      dispatch(set_shift_report_branch({_id:userBranchpk}))
      dispatch(set_shift_report_shift({_id:userShiftCode}))
      dispatch(set_shift_report_submit("submit"))
    }

// initial apicalls
  useEffect(() => {
    showExecuteRecurringPostDialog();
    viewPosSettingsAPICall();
    getAllActiveEmployeesAPICall();

    viewCashInOrCashOutAPICall();
    viewGlConfigAPICall();
    viewAllCurrencyUpdated({ status: 0 });
    getAllNewProductList();
    vendorListApi();
    customerListApi();
    companyProfileInfoForUser()
    viewAllBranchesAPICall()
    viewInventoryConfigSettingsAPICall();
    employeeRolesAndPermission({_id:employeeId})

    if (userInfo !== undefined) {
      if (userInfo !== null) {
        const { branchName } = userInfo;
        setBranchName(branchName);
      }
    }

    if (userRole === "user") {
      let branchId = localStorage.getItem("branchId");
      getShiftStatusAPICAll({
        branchId,
        curTime: `${today} ${getCurrentTime()}`,
      });
    }
  }, []);

  useEffect(() => {
    if (shiftDenominationTable.length !== 0) {
      let totalAmtArray = [];
      totalAmtArray = shiftDenominationTable.map((obj) => obj?.total);
      let grandTotal = 0;
      grandTotal = totalAmtArray.reduce((a, b) => a + b, 0);
      setShiftDenominationGrandTotal(grandTotal);
    }
  },[shiftDenominationTable]);

  useEffect(() => {
    if (userRole === "user") {
      let shiftBtnState = null;
      let timerLS = null;
      if (shiftStatus !== null) {
        shiftBtnState = shiftStatus?.status === "Active" ? true : false;
        timerLS = shiftStatus?.timer;
        setShiftBtnState(shiftBtnState);
        // setTimer(timerLS || 0);
      }
    }
  }, [shiftStatus]);

  //Call resume or pause on change of shift status
  useEffect(() => {
    if (JSON.parse(shiftBtnState)) {
      if (timer !== 0 && timer !== null) handleResume();
    } else {
      handlePause();
    }
  }, [shiftBtnState]);

  // to set default user and branch
  useEffect(()=>{
    if(allActiveEmpList!==undefined){
      let employeeObj=allActiveEmpList?.find((obj)=>obj?._id===userInfo?.data?._id)
      dispatch(setEmployeeDetails(employeeObj || null))
    }
    if(allBranchesList!==undefined){
      let employeeBranch=allBranchesList?.find((obj)=>obj?._id===userBranchpk)
      dispatch(setBranchDetails(employeeBranch || null))
    }
    if(companyProfileData!==undefined && currencyListUpdated!==undefined){
      let companyCurrency=currencyListUpdated?.find((obj)=>obj?._id===companyProfileData?.currency)
      dispatch(setCurrencyDetails(companyCurrency || null))
    }
  },[allActiveEmpList,allBranchesList,currencyListUpdated,companyProfileData])



  
  return (
    <>
      <ThemeProvider theme={mdTheme} >
        <Box sx={{ display: "flex"}} className={hideHeader && "top-hide"}>
          <CssBaseline  />
          <AppBar
            position="fixed"
            open={false}
            sx={{ ...(billingScreen && { marginLeft: "0px", width: "100%" }) }}
          >
            <Toolbar
              
              sx={{
                pr: "24px", // keep right padding when drawer closed
                backgroundColor: "#fff",
                display:hideHeader?'none':'flex',
                margin:"0px !important" ,

              }}
            >
              {/*<IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",

                  ...(open && { display: "none" }),
                  color: "#000",
                }}
              >
                <MenuIcon />
              </IconButton>*/}
              {/* empty space before topbar input box */}
              {/* custom component */}
              {/* <SearchInput /> */}
              <div className="dashboard-top-container desktop-logout">
                {(userRole === "user" && (
                  <div
                    className="dashboard-top-container-left-side"
                    style={{ width: "50%", justifyContent: "normal" }}
                  >
                    {/* {billingScreen && ( */}
                      <div
                        className="user-info"
                      >
                        <Avatar
                          src={userInfo?.imageUrl}
                        />

                        <p style={{ color: "black" }}>{userInfo?.userName}</p>
                      </div>
                    {/* )} */}
                    <div
                      className="dashboard-shift-status-main-container"
                      style={{ width: "45%" }}
                    >
                      <div className="shift-status">
                        <p>
                          Shift
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            {shiftStatus?.shiftId}
                          </span>
                        </p>
                        <div className="shift-status-active">
                          {shiftBtnState === true ? (
                            <div className="shift-status-active-round"></div>
                          ) : (
                            <div
                              className="shift-status-active-round"
                              style={{ backgroundColor: "rgb(173 168 168)" }}
                            ></div>
                          )}
                          {shiftBtnState === true ? (
                            <p>Active</p>
                          ) : (
                            <p style={{ color: "rgb(173 168 168)" }}>
                              Inactive
                            </p>
                          )}
                        </div>
                      </div>
                      <ShiftTimerComponent/>
                    </div>
                    {shiftBtnState ? (
                      <button
                        style={{ height: "30px", margin: "0 2%" }}
                        onClick={shiftEndBtnClick}
                        className="start-button end-button"
                      >
                        End
                      </button>
                    ) : (
                      <button
                        style={{ height: "30px", margin: "0 2%" }}
                        onClick={shiftStartBtnFn}
                        className="start-button"
                      >
                        Start
                      </button>
                    )}

                    {posSettingsList?.cashInOutStatus === true && (
                      <button
                        style={{
                          width: "25%",
                          borderRadius: "4px",

                          backgroundColor: "#087baf",

                          color: "#fff",
                          border: "none",
                          height: "30px",
                          cursor: "pointer",

                          // margin: "0 2%",
                          maxWidth:"fit-content"
                        }}
                        onClick={() => dispatch(setCashInOrOutDialog(true))}
                      >
                        Cash In Or Out
                      </button>
                    )}
                  </div>
                )) || <div className="dashboard-top-container-left-side"></div>}

                <div className="dashboard-top-container-right-side">
                  <div className="role-branch-code-container">
                    <div className="role-branch-child">
                      <span className="head-role">Role</span>
                      <span>:&nbsp;&nbsp;</span>
                      <span
                        style={{
                          color: "#1ebe69",
                          textTransform: "capitalize",
                        }}
                      >
                        {userRole}
                      </span>
                    </div>
                    {branchName !== "noBranch" && branchName !== "..." && (
                      <div className="role-branch-child">
                        <span className="head">Branch</span>
                        <span>:&nbsp;&nbsp;</span>
                        <span style={{ color: "#1ebe69" }}>{branchName}</span>
                      </div>
                    )}
                  </div>

                  {/* icons */}
                  <Tooltip title="Live Chat">
                    <IconButton>
                      <i class="bi bi-chat-text chat-icon"></i>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Notification">
                    <IconButton>
                      <NotificationsNoneIcon
                        style={{ fontSize: 24, color: "#000" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Stack direction={"row"} className="desktop-logout">
                    <Chip
                      label="Log Out"
                      onClick={logOutUser(
                        true,
                        userRole,
                        shiftBtnState,
                        shiftEndBtnClick
                      )}
                      icon={
                        <PowerSettingsNewIcon
                          style={{ fontSize: 16, color: "red" }}
                        />
                      }
                      style={{
                        fontWeight: 600,
                        color: "#000",
                        backgroundColor: "#f4f4f4",
                        marginLeft: 8,
                        cursor: "pointer",
                        border: "2px solid orange",
                      }}
                    ></Chip>
                  </Stack>

                  {
                    billingScreen && (
                      <IconButton
                        onClick={() => {
                          dispatch(
                            switch_to_billing_screen({ billingBoolean: false })
                          );
                          navigate("/userdashboard/dashboardhome");
                        }}
                      >
                        <HighlightOffOutlinedIcon
                          sx={{
                            color: "#f93737",
                            fontSize: "30px",
                            fontWeight: "400",
                          }}
                        />
                      </IconButton>
                    )
                  }
                </div>



                {/* show Execute Recurring Post */}
                <Dialog
                  open={executeRequiringDialog}
                  onClose={() => setExecuteRecurringDialog(false)}
                  maxWidth="lg"
                >
                  <div className="execute-recurring-post">
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        onClick={() => setExecuteRecurringDialog(false)}
                        className="close-btn"
                      >
                        <HighlightOffIcon fontSize="large" />
                      </button>
                    </div>

                    <div className="execute-recurring-post-container">
                      <h3>Execute Recurring Post</h3>
                      <div className="execute-recurring-btn-container">
                        <button
                          onClick={() => {
                            setExecuteRecurringDialog(false);
                            navigate(
                              "/userdashboard/accounts/createinvoice/recurringposting"
                            );
                          }}
                          className="btn btn-red"
                        >
                          YES
                        </button>
                        <button
                          onClick={() => setExecuteRecurringDialog(false)}
                          className="btn btn-black"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog>

                {/* password dialog */}
                <Dialog
                  open={shiftEndPasswordDialog}
                  onClose={() => setShiftEndPasswordDialog(false)}
                  maxWidth="lg"
                >
                  <div className="shift-end-button-password-container">
                    <div className="justify-space-between">
                      <h3>Enter Password</h3>
                      <Tooltip title="Shift Report">
                      <IconButton onClick={shiftSummaryReportClick}>
                        <ReceiptLongIcon color="primary"/>
                      </IconButton>
                      </Tooltip>
                    </div>
                    <div className="global-single-input shit-end-password-input">
                      <p>Password</p>
                      <input
                        type="password"
                        value={verifyPassWord}
                        onKeyDown={(e) =>
                          e.key === "Enter"
                            ? verifyUserPassword()
                            : e.key === "Escape"
                              ? () => setShiftEndPasswordDialog(false)
                              : undefined
                        }
                        onChange={(e) => {
                          setVerifyPassword(e.target.value);
                        }}
                      />
                    </div>
                    <button
                      onClick={verifyUserPassword}
                      className="btn btn-primary shift-end-password-btn"
                    >
                      Submit
                    </button>
                  </div>
                </Dialog>
                {/* error snackbar */}
                <ErrorSnackbar
                  open={errorSnackBar}
                  handleClose={errorSnackbarClose}
                  message={errorMsg}
                />
                <SuccessSnackbar
                  open={sucessSnackBar}
                  handleClose={successSnackbarClose}
                  message={errorMsg}
                />

                {/* SHIFT end denomination dialog */}
                <Dialog
                  open={denominationDialog}
                  maxWidth="lg"
                  onKeyDown={(e) =>
                    e.key === "Escape" && setDenominationDialog(false)
                  }
                >
                  <div className="shift-add-denomination-dialog">
                    <h4>Add Denomination</h4>
                    <div className="add-denomination-input-container-table">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <div className="global-single-input">
                                <p>Currency</p>
                                <Select
                                  value={shiftDenominationBody.currency}
                                  onChange={shiftDenominationGetForm(
                                    "currency"
                                  )}
                                >
                                  <MenuItem value="Rs">Rs</MenuItem>
                                  <MenuItem value="dollar">$</MenuItem>
                                  <MenuItem value="SAR">SAR</MenuItem>
                                </Select>
                              </div>
                            </th>
                            <th>
                              <div className="global-single-input">
                                <p>Amount</p>
                                <input
                                  type="number"
                                  value={shiftDenominationBody.amount}
                                  onChange={shiftDenominationGetForm("amount")}
                                  placeholder="0"
                                />
                              </div>
                            </th>
                            <th>
                              <div className="global-single-input">
                                <p>Count</p>
                                <input
                                  type="number"
                                  value={shiftDenominationBody.count}
                                  onChange={shiftDenominationGetForm("count")}
                                  placeholder="0"
                                />
                              </div>
                            </th>
                            <th>
                              <div className="global-single-input">
                                <p>Total</p>
                                <input
                                  type="number"
                                  value={shiftDenominationBody.total}
                                  disabled
                                />
                              </div>
                            </th>
                            <th>
                              <button
                                className="shift-single-add-btn"
                                onClick={clickAddSingleDenomination}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {shiftDenominationTable.length !== 0 ? (
                            shiftDenominationTable.map((r, i) => (
                              <tr key={i}>
                                <td style={{ width: "15%" }}>
                                  {r?.currency.toUpperCase()}
                                </td>
                                <td>{r?.amount}</td>
                                <td>{r?.count}</td>
                                <td>{r?.total}</td>
                                <td
                                  style={{
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    onClick={clickEditSingleDenomination(i)}
                                  >
                                    <i class="bi bi-pencil-square edit-icon"></i>
                                  </IconButton>
                                  <IconButton
                                    onClick={clickDelSingleDenomination(i)}
                                  >
                                    <i class="bi bi-trash3 delete-icon"></i>
                                  </IconButton>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colspan={5}
                                style={{
                                  backgroundColor: "#eee",
                                  border: "none",
                                }}
                              >
                                Empty
                              </td>
                            </tr>
                          )}
                          {shiftDenominationTable.length !== 0 && (
                            <tr>
                              <td style={{ border: "none" }}>&nbsp;</td>
                              <td style={{ border: "none" }}>&nbsp;</td>
                              <td
                                style={{ fontSize: "1rem", fontWeight: "700" }}
                              >
                                Grand Total
                              </td>
                              <td>{denominationGrandTotal}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="denomination-btn-container">
                      <button
                        onClick={() => setDenominationDialog(false)}
                        className="btn btn-secondary-outlined denomination-btn"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={denominationSubmitBtn}
                        className="btn btn-primary denomination-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Dialog>

                <Dialog
                  open={shiftReoprtOpen}
                  onClose={() => {
                      setShiftReportBtn(false)
                      dispatch(set_shift_report_branch(null))
                      dispatch(set_shift_report_shift(null))
                    }}
                  maxWidth='xl'
                  fullWidth
                >
                  <ShiftReportTable />
                  {/* hello */}
                </Dialog>
                <CashInOrOut/>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={false}
            className="drawer"
            style={{
              overflow: "hidden",
              height: "100vh",
              display: billingScreen && "none",
            }}
          >
               <SideMenuUpdated/>
          </Drawer>

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? "#edf2f9"
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            {/* this toolbar only for top margin  */}

            
            <Toolbar />

            <Routes>
              {/* Main Admin Dashboard */}
              {/* <Route path="dashboardhome" element={<HomeDashboard />}></Route> */}
              <Route path="dashboardhome" element={<HomeDashboardNew/>}></Route>


              {/* Product List Section  */}
              {/* {userRole === "user" ? (
                <>
                  {loginResponse?.permission?.productList === true && (
                    <Route path="productList" element={<ProductList />} />
                  )}
                </>
              ) : (
                <Route path="productList" element={<ProductList />} />
              )} */}

 
              <Route
                path="demo"
                element={
                <PrintDemo />
              }
              />
                <Route
                  path="403"
                  element={
                  <AccessDenied />
                  }
                />

              <Route 
                 path="productList" 
                 element={
                  <ProtectedRoute
                  element={ProductList}
                  isAuthenticated={permission?.products}
                  fallbackPath="/userdashboard/403"
                 />
                } 
                 
                 />

              <Route
                path="productList/singleview"
                element={
                  <ProtectedRoute
                  element={ProductListSingleView}
                  isAuthenticated={permission?.products}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />

            
              {/* Purchase Manager Section  */}

           

              <Route
                path="purchaseManager/RequestForQuatation"
                element={
                  <ProtectedRoute
                  element={RequestForQuatation}
                  isAuthenticated={permission?.purchase?.rfq}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />
              <Route
                path="purchaseManager/RequestForQuatation/singleView"
                element={
                  <ProtectedRoute
                  element={RequestForQuatationSingleView}
                  isAuthenticated={permission?.purchase?.rfq}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              <Route
                path="purchaseManager/CreateRequestForQuatation"
                element={
                  <ProtectedRoute
                  element={CreateRequestForQuotation1}
                  isAuthenticated={permission?.purchase?.rfq}
                  fallbackPath="/userdashboard/403"
                 />
                 }
              />

              <Route
                path="purchaseManager/NewPurchaseOrder"
                element={
                  <ProtectedRoute
                  element={NewPurchaseOrder}
                  isAuthenticated={permission?.purchase?.purchaseOrder}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />

              <Route
                path="purchaseManager/CreateNewPurchaseOrder"
                element={
                  <ProtectedRoute
                  element={CreateNewPurchaseOrder1}
                  isAuthenticated={permission?.purchase?.purchaseOrder}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

                <Route
                path="purchaseManager/PurchaseOrder/singleView"
                element={
                  <ProtectedRoute
                  element={PurchaseOrderSingleView}
                  isAuthenticated={permission?.purchase?.purchaseOrder}
                  fallbackPath="/userdashboard/403"
                 />
              }
              />

              <Route
                path="purchaseManager/GoodsReceivedNote"
                element={
                  <ProtectedRoute
                  element={GrnListing1}
                  isAuthenticated={permission?.purchase?.grn}
                  fallbackPath="/userdashboard/403"
                 />
                
              }
              />

              <Route
                path="purchaseManager/GoodsReceivedNote/SingleView"
                element={
                  <ProtectedRoute
                  element={GoodsReceivedNotesSingleView}
                  isAuthenticated={permission?.purchase?.grn}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              <Route
                path="purchaseManager/GoodsReceivedNote/add"
                element={<NewGoodsReceivedNotes1 />}
              />

              <Route
                path="purchasemanager/unitOfMeasurment"
                element={
                  <ProtectedRoute
                  element={UnitOfMeasurment}
                  isAuthenticated={permission?.purchase?.uom}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
                <Route path="purchasemanager/newUnit" element={
                   <ProtectedRoute
                   element={NewUOM}
                   isAuthenticated={permission?.purchase?.uom}
                   fallbackPath="/userdashboard/403"
                  />
                } />

              <Route
                path="purchasemanager/PurchaseReport/PurchaseReportNew"
                element={
                  <ProtectedRoute
                  element={PurchaseReportNew}
                  isAuthenticated={permission?.purchase?.purchaseReport }
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="purchasemanager/PurchaseReport/PurchaseReportNew/SingleView"
                element={<PurchaseReportSingleView />}
              />

            
              <Route
                path="purchaseManager/vendor/vendors"
                element={
                  <ProtectedRoute
                  element={VendorListPurchaseManage}
                  isAuthenticated={permission?.purchase?.vendors}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />
              <Route
                path="purchaseManager/vendor/Createvendors"
                element={
                  <ProtectedRoute
                  element={SalesCustomers}
                  isAuthenticated={permission?.purchase?.vendors}
                  fallbackPath="/userdashboard/403"
                  isCustomer={false}
                  isVendor={true}
                  isSales={false}
                  isPurchase={true}
                 />
                  
                }
              />
              {/* Transfer */}

              <Route
                path="purchaseManager/configuration"
                element={
                  <ProtectedRoute
                  element={ConfigurationPurchase}
                  isAuthenticated={permission?.purchase?.configuration}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              {/* Orders section */}

              {/* sales section */}
              <Route path="pos/billingnew" element={
                  <ProtectedRoute
                  element={BillingNew}
                  isAuthenticated={permission?.pointOfSales?.billing}
                  fallbackPath="/userdashboard/403"
                 />
        
              } />
              <Route path="pos/specialItems" element={
                 <ProtectedRoute
                 element={SpecialItems}
                 isAuthenticated={permission?.pointOfSales?.specialItems}
                 fallbackPath="/userdashboard/403"
                />
                 
                 } />
              <Route
                path="pos/specialItems/create"
                element={
                  <ProtectedRoute
                  element={CreateSpecialItems}
                  isAuthenticated={permission?.pointOfSales?.specialItems}
                  fallbackPath="/userdashboard/403"
                 />
                  
                }
              />
              <Route path="sales/billingnew" element={<BillingNew />} />


              {/* food management */}
              <Route path="foodManagement/configuration" 
                element={
                  <ProtectedRoute
                  element={ConfigurationFood}
                  isAuthenticated={permission?.foodManagement?.configuration}
                  fallbackPath="/userdashboard/403"
                 />
                } />
             
              <Route path="foodManagement/recipe/costAnalysis" element={<RecipeCostAnalysis/>} />
              <Route path="foodManagement/recipe" 
                  element={
                    <ProtectedRoute
                    element={RecipeFoodList}
                    isAuthenticated={permission?.foodManagement?.recipe}
                    fallbackPath="/userdashboard/403"
                   />
                 
                  } />
                   <Route path="foodManagement/recipe/create" element={
                     <ProtectedRoute
                     element={RecipeFood}
                     isAuthenticated={permission?.foodManagement?.recipe}
                     fallbackPath="/userdashboard/403"
                    />
                      
                 } />
             
              <Route path="foodManagement/preperation" 
                  element={
                    <ProtectedRoute
                    element={ManufacturingOrderList}
                    isAuthenticated={permission?.foodManagement?.preperation}
                    fallbackPath="/userdashboard/403"
                   />
                 
                  } />
                   <Route path="foodManagement/manufacturingOrder/create" element={
                   <ProtectedRoute
                   element={ManufacturingOrder}
                   isAuthenticated={permission?.foodManagement?.preperation}
                   fallbackPath="/userdashboard/403"
                  />
                   } />
              <Route path="foodManagement/manufacturingOrder/costAnalysis" element={<ManufacturingCostAnalysis/>} />



              {/* expense section */}
              <Route path="Expense/staffExpense" element={
                  <ProtectedRoute
                   element={StaffExpense}
                   isAuthenticated={permission?.pointOfSales?.expense?.staffExpense}
                   fallbackPath="/userdashboard/403"
                  />
              
              
              } />
              <Route path="expense/outletExpense" element={
                 <ProtectedRoute
                 element={OutletExpense}
                 isAuthenticated={permission?.pointOfSales?.expense?.outletExpense}
                 fallbackPath="/userdashboard/403"
                />
              
              } />
              <Route
                path="expense/pettyCashReceipt"
                element={
                  <ProtectedRoute
                  element={PettyCashReceipt}
                  isAuthenticated={userRole==="admin"}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="expense/addExpenseType"
                element={
                  <ProtectedRoute
                  element={AddExpenseType}
                  isAuthenticated={permission?.pointOfSales?.expense?.addExpenseType}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />

              {/* Report section */}
              <Route
                path="report/posSalesReport"
                element={<PosSalesReport />}
              />
              <Route path="report/stockReport" element={
                  <ProtectedRoute
                  element={StockReport}
                  isAuthenticated={permission?.report?.stockreport}
                  fallbackPath="/userdashboard/403"
                 />
              
              } />
              <Route
                path="report/stockSummaryReport"
                element={<StockSummaryReport />}
              />
             
              <Route
                path="report/stockAdjustmentReport"
                element={<StockAdjustmentReport />}
              />
              <Route path="report/expenseReport" element={
                 <ProtectedRoute
                 element={ExpenseReport}
                 isAuthenticated={permission?.report?.expenseReport}
                 fallbackPath="/userdashboard/403"
                />
                
                 } />
              {/* staff section */}

              <Route path="staff/dashboard" element={<StaffDashboard />} />
              <Route
                path="staff/hrm/manageEmployee/viewEmployee/viewEmpTotalCommission"
                element={<EmpDashboardTotalCommission />}
              />

              <Route path="staff/attendance" element={<StaffAttendance />} />

              <Route
                path="staff/leave/leaveApplication"
                element={<StaffLeaveApplication />}
              />
              <Route path="staff/leave/holiday" element={<StaffHoliday />} />
              <Route
                path="staff/leave/addLeaveType"
                element={<StaffAddLeaveType />}
              />
              

              <Route
                path="staff/payroll/employeeSalary"
                element={<StaffPayrollEmpSalary />}
              />
              <Route
                path="staff/payroll/addPayRoll"
                element={<StaffPayrollAddPayroll />}
              />
              <Route
                path="staff/payroll/payRollItem"
                element={<StaffPayrollItem />}
              />
              <Route path="staff/payroll/payslip" element={<StaffPaySlip />} />

              <Route
                path="staff/loan/addNewLoan"
                element={<StaffAddNewLoan />}
              />
              <Route
                path="staff/loan/loanReport"
                element={<StaffLoanReport />}
              />
              <Route
                path="staff/loan/loanApprovals"
                element={<StaffLoanApprovals />}
              />

              <Route path="staff/hrm/department" element={
                  <ProtectedRoute
                  element={Department}
                  isAuthenticated={permission?.staff?.hrm?.department}
                  fallbackPath="/userdashboard/403"
                 />
              
              } />
              <Route path="staff/hrm/designation" element={
                <ProtectedRoute
                element={Designation}
                isAuthenticated={permission?.staff?.hrm?.designation}
                fallbackPath="/userdashboard/403"
               />
             
              } />
              <Route
                path="staff/hrm/manageEmployee"
                element={
                  <ProtectedRoute
                  element={ManageEmployee}
                  isAuthenticated={permission?.staff?.hrm?.manageEmployee}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              <Route
                path="staff/hrm/manageEmployee/viewEmployee"
                element={<ViewEmployee />}
              />

              <Route path="staff/hrm/addEmployee" element={
                 <ProtectedRoute
                 element={AddEmployee}
                 isAuthenticated={permission?.staff?.hrm?.addEmployee}
                 fallbackPath="/userdashboard/403"
                />
                
                 } />
              <Route path="staff/hrm/addDocument" element={
                <ProtectedRoute
                element={AddDocument}
                isAuthenticated={permission?.staff?.hrm?.addDocument}
                fallbackPath="/userdashboard/403"
               />
                } />
              <Route
                path="staff/hrm/addDocument/documentprint"
                element={<DocumentPrintView />}
              />

              {/* Device Settings */}

              <Route path="Devicesettings" element={<DeviceSettings />} />

              {/* settings section */}
              <Route path="settings" element={<Settings />} />
              

              <Route path="qr/barcode" element={<GenerateQrOrBarcode />} />

              {/* account section */}
              {/* customers */}

              <Route
                path="accounts/"
                element={
                  <ProtectedRoute
                  element={AccountDashboard}
                  isAuthenticated={permission?.accounts?.dashboard}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="accounts/configuration"
                element={
                  <ProtectedRoute
                  element={Configuration}
                  isAuthenticated={permission?.accounts?.configuration}
                  fallbackPath="/userdashboard/403"
                 />
               
                }
              />
              <Route
                path="accounts/customers/customerInvoices"
                element={
                  <ProtectedRoute
                  element={CustomerInvoice}
                  isAuthenticated={permission?.accounts?.customers?.customerInvoice}
                  fallbackPath="/userdashboard/403"
                 />
                
                }
              />

              <Route
                path="accounts/customers/customerInvoices/singleView"
                element={
                  <ProtectedRoute
                  element={CustomerInvoiceSingleView}
                  isAuthenticated={permission?.accounts?.customers?.customerInvoice}
                  fallbackPath="/userdashboard/403"
                 />
             }
              />

              <Route
                path="accounts/customers/customerInvoices/create"
                element={
                  <ProtectedRoute
                  element={CreateCustomerInvoice}
                  isAuthenticated={permission?.accounts?.customers?.customerInvoice}
                  fallbackPath="/userdashboard/403"
                 />
              }
              />
              <Route
                path="accounts/customers/saleswso"
                element={
                  <ProtectedRoute
                  element={SalesWSO}
                  isAuthenticated={permission?.accounts?.customers?.saleswoso}
                  fallbackPath="/userdashboard/403"
                 />
            
                }
              />
              <Route
                path="accounts/customers/saleswso/create"
                element={
                  <ProtectedRoute
                  element={CreateSalesWSO}
                  isAuthenticated={permission?.accounts?.customers?.saleswoso}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/customers/saleswso/singleView"
                element={
                  <ProtectedRoute
                  element={SalesWSOsingleView}
                  isAuthenticated={permission?.accounts?.customers?.saleswoso}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />
              <Route
                path="accounts/customer/payments"
                element={
                  <ProtectedRoute
                  element={CustomerPayments}
                  isAuthenticated={permission?.accounts?.customers?.payments}
                  fallbackPath="/userdashboard/403"
                 />
               
                }
              />
              <Route
                path="accounts/customer/payments/create"
                element={
                  <ProtectedRoute
                  element={CreateCustomerPayment}
                  isAuthenticated={permission?.accounts?.customers?.payments}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/customers/creditNotes"
                element={
                  <ProtectedRoute
                  element={CustomerCreditNotes}
                  isAuthenticated={permission?.accounts?.customers?.creditNotes}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />

              <Route
                path="accounts/customers/creditNotes/SingleView"
                element={
                  <ProtectedRoute
                  element={CreaditNoteSingleView}
                  isAuthenticated={permission?.accounts?.customers?.creditNotes}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />
              <Route
                path="accounts/customers/creditNotes/create"
                element={
                  <ProtectedRoute
                  element={CreateCustomerCreditNotes}
                  isAuthenticated={permission?.accounts?.customers?.creditNotes}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              {/* <Route
                path="accounts/customers/customers"
                element={<Vendors />}
              /> */}

              <Route
                path="accounts/customers/customers"
                element={
                  <ProtectedRoute
                  element={Customers}
                  isAuthenticated={permission?.accounts?.customers?.customers}
                  fallbackPath="/userdashboard/403"
                  isSales={false}
                 />
                // <Customers isSales={false} />
              }
              />

              <Route
                path="accounts/customers/customers/create"
                element={
                  <ProtectedRoute
                  element={SalesCustomers}
                  isAuthenticated={permission?.accounts?.customers?.customers}
                  fallbackPath="/userdashboard/403"
                  isCustomer={true}
                  isVendor={false}
                  isSales={false}
                  isPurchase={false}
                 />
                
                }
              />

              <Route
                path="accounts/customers/customers/create1"
                element={<CreateCustomer />}
              />
              <Route
                path="accounts/Reconciliation/BankReconciliation"
                element={
                  <ProtectedRoute
                  element={BankReconciliationNew}
                  isAuthenticated={permission?.accounts?.reconcilation?. bankReconcilation}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />

              <Route
                path="accounts/Reconciliation/BankReconciliation/Create"
                element={
                  <ProtectedRoute
                  element={CreateBankReconciliation}
                  isAuthenticated={permission?.accounts?.reconcilation?. bankReconcilation}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/vatReport/inputOrOutputReport"
                element={
                  <ProtectedRoute
                  element={InputOrOutputReport}
                  isAuthenticated={permission?.accounts?.vatreport?.inputoroutputreport}
                  fallbackPath="/userdashboard/403"
                 />
                   }
              />

              {/* accounting */}
              <Route path="accounts/accounting/journal" element={
                 <ProtectedRoute
                 element={Journal}
                 isAuthenticated={permission?.accounts?.accounting?.journal}
                 fallbackPath="/userdashboard/403"
                />
              
                } />
              <Route
                path="accounts/accounting/journal/createjournal"
                element={
                  <ProtectedRoute
                  element={CreateJournal}
                  isAuthenticated={permission?.accounts?.accounting?.journal}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/accounting/chartofaccounts"
                element={
                  <ProtectedRoute
                  element={ChartOfAccounts}
                  isAuthenticated={permission?.accounts?.accounting?.chartofaccounts}
                  fallbackPath="/userdashboard/403"
                 />
                 
                }
              />
              <Route
                path="accounts/accounting/createchartofaccounts"
                element={
                  <ProtectedRoute
                  element={CreateChartOfAccount}
                  isAuthenticated={permission?.accounts?.accounting?.chartofaccounts}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
               <Route
                path="accounts/accounting/openingBalance"
                element={
                  <ProtectedRoute
                  element={OpeningBalance}
                  isAuthenticated={permission?.accounts?.accounting?.openingBalance}
                  fallbackPath="/userdashboard/403"
                 />
                
                }
              />
              <Route
                path="accounts/accounting/openingBalance/create"
                element={
                  <ProtectedRoute
                  element={CreateOpeningBalance}
                  isAuthenticated={permission?.accounts?.accounting?.openingBalance}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/accounting/journalEntries"
                element={
                  <ProtectedRoute
                  element={JournalEntries}
                  isAuthenticated={permission?.accounts?.accounting?.journalEntries}
                  fallbackPath="/userdashboard/403"
                 />
                
                }
              />
              <Route
                path="accounts/accounting/journalEntriesCreate"
                element={
                  <ProtectedRoute
                  element={JournalEntriesForm }
                  isAuthenticated={permission?.accounts?.accounting?.journalEntries}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/accounting/recurringposting"
                element={<ReccurringPosting />}
              />
              {/* ACcounts vendor */}

              <Route
                path="accounts/vendor/vendorBills"
                element={
                  <ProtectedRoute
                  element={VendorBills1}
                  isAuthenticated={permission?.accounts?.vendor?.vendorBills}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />

              <Route
                path="accounts/vendor/SingleView"
                element={
                  <ProtectedRoute
                  element={VendorBillSingleView}
                  isAuthenticated={permission?.accounts?.vendor?.vendorBills}
                  fallbackPath="/userdashboard/403"
                 />
              }
              />
              <Route
                path="accounts/vendor/createvendorBills"
                element={
                  <ProtectedRoute
                  element={CreateVendorBills1}
                  isAuthenticated={permission?.accounts?.vendor?.vendorBills}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />



              <Route
                path="accounts/vendor/payments"
                element={
                  <ProtectedRoute
                  element={VendorPayments}
                  isAuthenticated={permission?.accounts?.vendor?.payments}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="accounts/vendor/createvendorPayments"
                element={
                  <ProtectedRoute
                  element={CreateVendorPayments}
                  isAuthenticated={permission?.accounts?.vendor?.payments}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />
              <Route
                path="accounts/vendor/DebitNotes"
                element={
                  <ProtectedRoute
                  element={DebitNotes}
                  isAuthenticated={permission?.accounts?.vendor?.debitNotes}
                  fallbackPath="/userdashboard/403"
                 />
             
              }
              />

              <Route
                path="accounts/vendor/DebitNotes/create"
                element={
                  <ProtectedRoute
                  element={CreateDebitNote}
                  isAuthenticated={permission?.accounts?.vendor?.debitNotes}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
               <Route
                path="accounts/vendor/DebitNote/SingleView"
                element={
                  <ProtectedRoute
                  element={DebitNoteSingleView}
                  isAuthenticated={permission?.accounts?.vendor?.debitNotes}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route path="accounts/vendor/vendors" element={
                 <ProtectedRoute
                 element={Vendors}
                 isAuthenticated={permission?.accounts?.vendor?.vendors}
                 fallbackPath="/userdashboard/403"
                />
                
                 } />
              <Route
                path="accounts/vendor/Createvendors"
                element={
                  <ProtectedRoute
                  element={SalesCustomers}
                  isAuthenticated={permission?.accounts?.vendor?.vendors}
                  fallbackPath="/userdashboard/403"
                  isVendor={true}
                  isCustomer={false}
                  isSales={false}
                  isPurchase={false}
                 />
                 
                }
              />

              <Route
                path="accounts/vendor/Createvendors1"
                element={<CreateVendors />}
              />

              <Route
                path="accounts/vendor/purchasewopo"
                element={
                  <ProtectedRoute
                  element={PurchaseWOPO1}
                  isAuthenticated={permission?.accounts?.vendor?.purchasewpo}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />

              <Route
                path="accounts/vendor/purchasewopo/create"
                element={
                  <ProtectedRoute
                  element={CreatePurchaseWOPO1}
                  isAuthenticated={permission?.accounts?.vendor?.purchasewpo}
                  fallbackPath="/userdashboard/403"
                 />
                 }
              />
              <Route
                path="accounts/vendor/purchasewopo/SingleView"
                element={
                  <ProtectedRoute
                  element={PurchaseWOPOsingleView}
                  isAuthenticated={permission?.accounts?.vendor?.purchasewpo}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />

             

              {/* Account Report*/}
              <Route
                path="accounts/report/financialReport/generalLedger"
                element={
                  <ProtectedRoute
                  element={GeneralLedgerNw}
                  isAuthenticated={permission?.accounts?.report?.financialReport?.generalLedger}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/report/financialReport/trialbalance"
                element={
                  <ProtectedRoute
                  element={TrialBalance}
                  isAuthenticated={permission?.accounts?.report?.financialReport?.trialBalance}
                  fallbackPath="/userdashboard/403"
                 />
                   }
              />
              <Route
                path="accounts/report/financialReport/balancesheet"
                element={
                  <ProtectedRoute
                  element={BalanceSheet}
                  isAuthenticated={permission?.accounts?.report?.financialReport?.balanceSheet}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/report/financialReport/Profit&Loss"
                element={
                  <ProtectedRoute
                  element={ProfitAndLoss}
                  isAuthenticated={permission?.accounts?.report?.financialReport?.profitAndLoss}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/report/financialReport/trialbalance/ledgerBalance"
                element={<LedgerBalance />}
              />

              {/* Partner Report */}
              <Route
                path="accounts/report/partnerReport/accountPayable"
                element={
                  <ProtectedRoute
                  element={AccountPayable}
                  isAuthenticated={permission?.accounts?.report?.partnerReports?.accountPayable}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/report/partnerReport/accountPayable/singleView"
                element={<AccountPayableSingleView />}
              />
                <Route
                path="sales/Report/CustomerReceiptReport"
                element={
                  <ProtectedRoute
                  element={CustomerReceiptReport}
                  isAuthenticated={permission?.accounts?.report?.generalReport?.customerReciept}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="sales/Report/CustomerReceiptReportSingleView"
                element={<CustomerReceiptReportSingleView />}
              />
              <Route
                path="accounts/report/partnerReport/accountReceivable"
                element={
                  <ProtectedRoute
                  element={AccountRecievable}
                  isAuthenticated={permission?.accounts?.report?.partnerReports?.accountReceivable}
                  fallbackPath="/userdashboard/403"
                 />
                 }
              />

              <Route
                path="accounts/report/partnerReport/accountReceivable/singleView"
                element={<AccountRecievableSingleView />}
              />
              <Route
                path="accounts/report/partnerReport/AccountReceivableAgingReport"
                element={
                  <ProtectedRoute
                  element={AccountReceivableAgingReport}
                  isAuthenticated={permission?.accounts?.report?.partnerReports?.agingReportReceivable}
                  fallbackPath="/userdashboard/403"
                 />
                 }
              />
              <Route
                path="accounts/report/partnerReport/AccountReceivableAgingReportSingleView"
                element={<AccountReceivableAgingReportSingleView />}
              />
              <Route
                path="accounts/report/partnerReport/AccountPayableAgingReport"
                element={
                  <ProtectedRoute
                  element={AccountPayableAgingReport}
                  isAuthenticated={permission?.accounts?.report?.partnerReports?.agingReportPayable}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />
              <Route
                path="accounts/report/partnerReport/AccountPayableAgingReportSingleView"
                element={<AccountPayableAgingReportSingleView />}
              />
                <Route
                path="accounts/report/partnerReport/ReceivableDueReport"
                element={
                  <ProtectedRoute
                  element={ReceivableDueReport}
                  isAuthenticated={permission?.accounts?.report?.partnerReports?.receivableDueReport}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/report/partnerReport/ReceivableDueReport/SingleView"
                element={<ReceivableDueReportSingleView />}
              />
              <Route
                path="accounts/report/partnerReport/PayableDueReport"
                element={
                  <ProtectedRoute
                  element={PyableDueReport}
                  isAuthenticated={permission?.accounts?.report?.partnerReports?.payableDueReport}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />
              <Route
                path="accounts/report/partnerReport/PayableDueReport/SingleView"
                element={<PayableDueReportSingleView />}
              />

              {/* General Report */}
              <Route
                path="accounts/report/generalReport/bankAndCashReport"
                element={
                  <ProtectedRoute
                  element={BankAndCashReport}
                  isAuthenticated={permission?.accounts?.report?.generalReport?.bankAndCash}
                  fallbackPath="/userdashboard/403"
                 />
                 }
              />
              <Route
                path="accounts/report/generalReport/bankAndCashReport/singleView"
                element={<BankAndCashReportSingleView />}
              />
               <Route
                path="accounts/report/generalReport/ChequeRegisterReport"
                element={
                  <ProtectedRoute
                  element={ChequeRegisterReport}
                  isAuthenticated={permission?.accounts?.report?.generalReport?.chequeRegister}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
                <Route
                path="accounts/report/generalReport/InvoiceMarginReport"
                element={
                  <ProtectedRoute
                  element={InvoiceMarginReport}
                  isAuthenticated={permission?.accounts?.report?.generalReport?.invoiceMargin}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />
              <Route
                path="accounts/report/generalReport/InvoiceMarginReport/SingleView"
                element={<InvoiceMarginReportSingleView />}
              />
               <Route
                path="accounts/report/generalReport/productMarginReport"
                element={
                  <ProtectedRoute
                  element={ProductMarginReport}
                  isAuthenticated={permission?.accounts?.report?.generalReport?.productMargin}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="accounts/report/generalReport/productMarginReport/SingleView"
                element={<ProductMarginReportSingleView />}
              />

              {/* configuration */}

              {/* inventory */}
              <Route
                path="inventory/configuration/warehouse"
                element={
                  <ProtectedRoute
                  element={WareHouse}
                  isAuthenticated={permission?.inventory?.configuration?.warehouse}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="inventory/configuration/createwarehouse"
                element={
                  <ProtectedRoute
                  element={CreateWareHouse}
                  isAuthenticated={permission?.inventory?.configuration?.warehouse}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              <Route
                path="inventory/configuration/location"
                element={
                  <ProtectedRoute
                  element={InventoryLocation}
                  isAuthenticated={permission?.inventory?.configuration?.location}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />

              <Route
                path="inventory/configuration/location/create"
                element={
                  <ProtectedRoute
                  element={CreateInvLocation }
                  isAuthenticated={permission?.inventory?.configuration?.location}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              <Route
                path="inventory/configuration/settings"
                element={
                  <ProtectedRoute
                  element={SettingsProductVariant}
                  isAuthenticated={permission?.inventory?.configuration?.settings}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />
              <Route
                path="inventory/configuration/attribute"
                element={
                  <ProtectedRoute
                  element={Attribute}
                  isAuthenticated={permission?.inventory?.configuration?.attribute}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="inventory/configuration/attribute/create"
                element={
                  <ProtectedRoute
                  element={CreateAttribute}
                  isAuthenticated={permission?.inventory?.configuration?.attribute}
                  fallbackPath="/userdashboard/403"
                 />
              }
              />
              <Route
                path="inventory/configuration/poscategory"
                element={
                  <ProtectedRoute
                  element={POSCategory}
                  isAuthenticated={permission?.inventory?.configuration?.poscategory}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="inventory/configuration/poscategory/create"
                element={
                  <ProtectedRoute
                  element={CreatePOSCategory}
                  isAuthenticated={permission?.inventory?.configuration?.poscategory}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="inventory/configuration/category"
                element={
                  <ProtectedRoute
                  element={Category}
                  isAuthenticated={permission?.inventory?.configuration?.category}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />
                <Route
                path="inventory/configuration/category/create"
                element={
                  <ProtectedRoute
                  element={CreateCategory}
                  isAuthenticated={permission?.inventory?.configuration?.category}
                  fallbackPath="/userdashboard/403"
                 />
                  }
              />

              <Route
                path="inventory/reports/stockMove"
                element={
                  <ProtectedRoute
                  element={StockMove}
                  isAuthenticated={permission?.inventory?.reports?.stockMoveReport}
                  fallbackPath="/userdashboard/403"
                 />
                
              }
              />
              <Route
                path="inventory/reports/stockMove/singleView"
                element={<StockMoveSingleView />}
              />

            
              <Route
                path="inventory/operation/Interanltransfer"
                element={
                  <ProtectedRoute
                  element={InternalTransfer}
                  isAuthenticated={permission?.inventory?.operations?.internalTransfer}
                  fallbackPath="/userdashboard/403"
                 />
                
              }
              />
                <Route
                path="inventory/operation/InternalTransfer/create"
                element={
                  <ProtectedRoute
                  element={CreateInternalTransfer}
                  isAuthenticated={permission?.inventory?.operations?.internalTransfer}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />
              <Route
                path="inventory/operation/branchtransfer"
                element={
                  <ProtectedRoute
                  element={BranchTransfer}
                  isAuthenticated={permission?.inventory?.operations?.branchTransfer}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />
                <Route
                path="inventory/operation/branchtransfer/create"
                element={
                  <ProtectedRoute
                  element={CreateBranchTransfer}
                  isAuthenticated={permission?.inventory?.operations?.branchTransfer}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              <Route
                path="inventory/operation/inventoryAdjustment"
                element={
                  <ProtectedRoute
                  element={InventoryAdjustment}
                  isAuthenticated={permission?.inventory?.operations?.inventoryAdjustments}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />
              <Route
                path="inventory/operation/inventoryAdjustment/create"
                element={
                  <ProtectedRoute
                  element={CreateInventoryAdjustment}
                  isAuthenticated={permission?.inventory?.operations?.inventoryAdjustments}
                  fallbackPath="/userdashboard/403"
                 />
                
              }
              />
              <Route
                path="inventory/operation/landedcost"
                element={<LandedCost />}
              />
              <Route
                path="inventory/operation/landedcost/create"
                element={<CreateLandedCost />}
              />
              <Route
                path="inventory/operation/stockmoves"
                element={
                  <ProtectedRoute
                  element={StockMoves}
                  isAuthenticated={permission?.inventory?.operations?.stockMoves}
                  fallbackPath="/userdashboard/403"
                 />
                
              }
              />
            
         

              <Route
                path="inventory/operation/branchreceipt"
                element={
                  <ProtectedRoute
                  element={BranchReceipt}
                  isAuthenticated={permission?.inventory?.operations?.branchReciept}
                  fallbackPath="/userdashboard/403"
                 />
          
              }
              />
              <Route
                path="inventory/operation/branchreceipt/create"
                element={<CreateBranchReceipt />}
              />

              {/* inventory-operation-product */}

              <Route
                path="inventory/products/product"
                element={
                  <ProtectedRoute
                  element={ProductListUpdated}
                  isAuthenticated={permission?.inventory?.products?.product}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />
              <Route
                path="inventory/products/product/create"
                element={
                  <ProtectedRoute
                  element={CreateProductList}
                  isAuthenticated={permission?.inventory?.products?.product}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />
              <Route
                path="inventory/products/productmaster"
                element={
                  <ProtectedRoute
                  element={ProductMasterUpdated}
                  isAuthenticated={permission?.inventory?.products?.productMaster}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="inventory/products/productmaster/create"
                element={
                  <ProtectedRoute
                  element={CreateProductMaster}
                  isAuthenticated={permission?.inventory?.products?.productMaster}
                  fallbackPath="/userdashboard/403"
                 />
                 }
              />
              {/* sales new */}
              <Route
                path="sales/orders/quotation"
                element={
                  <ProtectedRoute
                  element={SalesQuotation}
                  isAuthenticated={permission?.sales?.quotation}
                  fallbackPath="/userdashboard/403"
                 />
                
              }
              />
              <Route
                path="sales/orders/quotation/create"
                element={
                  <ProtectedRoute
                  element={CreateSalesQuotation}
                  isAuthenticated={permission?.sales?.quotation}
                  fallbackPath="/userdashboard/403"
                  />
                 }
              />
              <Route
                path="sales/orders/quotation/singleView"
                element={
                  <ProtectedRoute
                  element={SalesQuatationSingleVieww}
                  isAuthenticated={permission?.sales?.quotation}
                  fallbackPath="/userdashboard/403"
                  />
               }
              />

              <Route path="sales/orders/salesOrder" 
              element={
                <ProtectedRoute
                  element={SalesOrder}
                  isAuthenticated={permission?.sales?.salesOrder}
                  fallbackPath="/userdashboard/403"
                 />
              
              } />
              <Route
                path="sales/orders/salesOrder/create"
                element={
                  <ProtectedRoute
                  element={CreateSalesOrder}
                  isAuthenticated={permission?.sales?.salesOrder}
                  fallbackPath="/userdashboard/403"
                 />
               }
              />

              <Route
                path="sales/orders/salesOrder/singleView"
                element={
                  <ProtectedRoute
                  element={SalesOrderSingleView}
                  isAuthenticated={permission?.sales?.salesOrder}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              <Route
                path="sales/orders/deliveryNote"
                element={
                  <ProtectedRoute
                  element={DeliveryNote}
                  isAuthenticated={permission?.sales?.deliveryNote}
                  fallbackPath="/userdashboard/403"
                 />
                
              }
              />

                <Route
                path="sales/orders/deliveryNote/SingleView"
                element={
                  <ProtectedRoute
                  element={DeliverNoteSingleView}
                  isAuthenticated={permission?.sales?.deliveryNote}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="sales/orders/deliveryNote/create"
                element={
                  <ProtectedRoute
                  element={CreateDeliiveryNote }
                  isAuthenticated={permission?.sales?.deliveryNote}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              <Route
                path="sales/orders/customers"
                element={
                  <ProtectedRoute
                  element={Customers}
                  isAuthenticated={permission?.sales?.customers}
                  fallbackPath="/userdashboard/403"
                  isSales={true}
                 />
                // <Customers isSales={true} />
              }
              />
              <Route
                path="sales/orders/customers/create"
                element={
                  <SalesCustomers
                    isCustomer={true}
                    isVendor={false}
                    isSales={true}
                    isPurchase={false}
                  />
                }
              />

           
              <Route
                path="sales/orders/pricelist/create"
                element={<CreatePriceList />}
              />
              <Route path="sales/orders/pricelist" element={<PriceList />} />
              <Route
                path="sales/Report/salesReport"
                element={
                  <ProtectedRoute
                  element={SalesNewReport}
                  isAuthenticated={permission?.sales?.report?.salesReport}
                  fallbackPath="/userdashboard/403"
                 />
                 
                }
              />
              <Route
                path="sales/Report/salesReportSingleView"
                element={<SalesReportSingleView />}
              />
                 <Route
                path="sales/Report/salesReportBySalesPerson"
                element={
                  <ProtectedRoute
                  element={SalesReportBySalesPerson}
                  isAuthenticated={permission?.sales?.report?.salesReportBySalesPerson}
                  fallbackPath="/userdashboard/403"
                 />
                
              }
              />
              <Route
                path="sales/Report/salesReportBySalesSingleView"
                element={<SalesReportBySalesSingleView />}
              />

              {/* point of sale */}
              {/* configuration */}
              <Route
                path="pointOfSales/configuration/settings"
                element={
                  <ProtectedRoute
                  element={PosSettings}
                  isAuthenticated={permission?.pointOfSales?.configuration?.settings}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="pointOfSales/configuration/BranchSettings"
                element={
                  <ProtectedRoute
                  element={BranchSettings}
                  isAuthenticated={permission?.pointOfSales?.configuration?.branchSettings}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="pointOfSales/configuration/BranchSettings/create"
                element={
                  <ProtectedRoute
                  element={BranchSettingsForm}
                  isAuthenticated={permission?.pointOfSales?.configuration?.branchSettings}
                  fallbackPath="/userdashboard/403"
                 />
              }
              />
              <Route
                path="pointOfSales/configuration/Aggregator"
                element={
                  <ProtectedRoute
                  element={Aggregator}
                  isAuthenticated={permission?.pointOfSales?.configuration?.aggregator}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
            {/* pos general */}
              <Route
                path="pointOfSales/general/shift"
                element={
                  <ProtectedRoute
                  element={PosShift}
                  isAuthenticated={permission?.pointOfSales?.general?.shift}
                  fallbackPath="/userdashboard/403"
                 />
                
              }
              />
              <Route
                path="pointOfSales/general/shiftSingleView"
                element={
                  <ProtectedRoute
                  element={PosShiftSingleView}
                  isAuthenticated={permission?.pointOfSales?.general?.shift}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />
              <Route
                path="pointOfSales/general/orders"
                element={
                  <ProtectedRoute
                  element={PosOrders}
                  isAuthenticated={permission?.pointOfSales?.general?.orders}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              
              <Route
                path="pointOfSales/general/orderSingleView"
                element={
                  <ProtectedRoute
                  element={PosOrdersSingleView}
                  isAuthenticated={permission?.pointOfSales?.general?.orders}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              <Route
                path="pointOfSales/general/payments"
                element={
                  <ProtectedRoute
                  element={PosPayment}
                  isAuthenticated={permission?.pointOfSales?.general?.payments}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              <Route
                path="pointOfSales/general/wallet"
                element={
                  <ProtectedRoute
                  element={PosWallet}
                  isAuthenticated={permission?.pointOfSales?.general?.wallet}
                  fallbackPath="/userdashboard/403"
                 />
                }
              />

              {/* {pos Report section} */}
              <Route
                path="pointOfSales/report/shiftReport"
                element={
                  <ProtectedRoute
                  element={PosShiftReport}
                  isAuthenticated={permission?.pointOfSales?.report?.shiftReport}
                  fallbackPath="/userdashboard/403"
                 />
                  }
              />
              <Route
                path="PointOfSales/report/shiftReportnew"
                element={
                  <ProtectedRoute
                  element={PosNewShiftReport}
                  // isAuthenticated={permission?.PointOfSales?.report?.shiftReportNew}
                  isAuthenticated={true}
                  fallbackPath="/userdashboard/403"
                 />
                  }
              />
              <Route
                path="pointOfSales/report/shiftSummaryReport"
                element={
                  <ProtectedRoute
                  element={PosShiftSummaryReport}
                  isAuthenticated={permission?.pointOfSales?.report?.shiftSummaryReport}
                  fallbackPath="/userdashboard/403"
                 />
           
              }
              />
              <Route
                path="pointOfSales/report/salesDetails"
                element={
                  <ProtectedRoute
                  element={PosSalesDetails}
                  isAuthenticated={permission?.pointOfSales?.report?.salesDetails}
                  fallbackPath="/userdashboard/403"
                 />
                
              }
              />
              <Route
                path="pointOfSales/report/aggregatorReport"
                element={
                  <ProtectedRoute
                  element={PosAggregatorReport}
                  isAuthenticated={permission?.pointOfSales?.report?.aggregatorReport}
                  fallbackPath="/userdashboard/403"
                 />
              
              }
              />
               <Route
                path="pointOfSales/report/DailyCashCardSummary"
                element={
                  <ProtectedRoute
                  element={DailyCashCardSummary}
                  isAuthenticated={permission?.pointOfSales?.report?.cashcardSummary}
                  fallbackPath="/userdashboard/403"
                 />
             
              }
              />

              {/* payroll section */}
              <Route path="payroll/Establishment" element={<EstablisId />} />
              <Route
                path="payroll/Attendance/Overtime"
                element={<OvertimeList />}
              />
              <Route
                path="payroll/configuration/payHead"
                element={<PayHead />}
              />
              <Route
                path="payroll/configuration/payHead"
                element={<PayHead />}
              />
              <Route path="invoiceView" element={<QrcodeScanView />} />
              {/* floor management  */}
              <Route path="floorManagement" element={
                  <ProtectedRoute
                  element={AddSeat}
                  isAuthenticated={permission?.pointOfSales?.floorManagement}
                  fallbackPath="/userdashboard/403"
                 />
             
              } />
              {/* kitchen order ticket  */}
              <Route
                path="kitchenOrderTicket"
                element={
                  <ProtectedRoute
                  element={KitchenOrderTicket}
                  isAuthenticated={permission?.pointOfSales?.kot}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              {/* Delivery Counter screen  */}
              <Route
                path="deliveryCounter"
                element={
                  <ProtectedRoute
                  element={DeliveryScreen}
                  isAuthenticated={true}
                  // isAuthenticated={permission?.pointOfSales?.kot}
                  fallbackPath="/userdashboard/403"
                 />
               
              }
              />
              {/* kitchen order token display  */}
              <Route path="tokenDisplay" element={
                 <ProtectedRoute
                 element={KotTokenDisplay}
                 isAuthenticated={permission?.pointOfSales?.tokenDisplay}
                 fallbackPath="/userdashboard/403"
                />
             
                 } />
              {/* customer display  */}
              <Route path="customerDisplay" element={
                  <ProtectedRoute
                  element={CustomerDisplay}
                  isAuthenticated={permission?.pointOfSales?.customerDisplay}
                  fallbackPath="/userdashboard/403"
                 />
             
              
              } />

              {/* test screen  */}
              <Route path="testPage" element={<DeliveryScreen />} />
              {/* pos print priveiw screen  */}
              <Route path="posReaderPreview" element={<PosReaderPreview />} />

            </Routes>

            {/* <Typography>home</Typography> */}

            <IconButton
              onClick={handleFullscreen}
              sx={{position:"absolute",
                   top:"93%",
                   left:"10px",
                   boxShadow:"0 8px 16px rgba(0, 0, 0, 0.3)",
                   backgroundColor:"#fafafa"
              }}
            >
            {fullScreenEnable?
              <FullscreenExitIcon/>
              :<FullscreenIcon/>
            }
            </IconButton>
            <SuccessSnackbar
              open={successSnack}
              handleClose={closeSnackbar}
              message={snackMsg}
            />
            <ErrorSnackbar
              open={errorSnack}
              handleClose={closeSnackbar}
              message={snackMsg}
            />
            <LoadingForm loading={submitLoading} />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
