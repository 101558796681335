import React from 'react'
import { useSelector } from 'react-redux';

function CategorySummary({showSubHeaders}) {
    const getBackgroundColor = (index) => {
        return index % 2 === 0 ? "#ffffff" : "#dfdfdf";
      };
      const CategorySalesSummary = useSelector((state)=>state?.posReportshiftSlice?.categorySalesSummary);
      const decimalPosition = localStorage.getItem("decimalPosition");
  return (
    <div
    className="new-global-table-container"
    style={{ paddingTop: "0 42px" }}
  >
    <div className="bill-main-head">
      <h5>Category Name</h5>
      <h5>Amount</h5>
    </div>
    {CategorySalesSummary?.map((partner, index) => (
          <>
              <div key={partner?._id} className="bill-sub-head"  style={{ backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(index) }}>
                <h5>{partner?.categoryName}</h5>
                <h5>{partner?.totalAmount?.toFixed(decimalPosition)}</h5>
              </div>
            
              {showSubHeaders && (
                <table>
                  <thead style={{ backgroundColor: "#C0CFF8" }}>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partner?.products?.map((order, orderIndex) => (
                      <tr key={orderIndex}>
                        <td style={{ borderBottom: "1px solid black", height: "29px" }}>
                          {order?.product}
                        </td>
                        <td style={{ borderBottom: "1px solid black", height: "29px" }}>
                          {order?.quantity}
                        </td>
                        <td style={{ borderBottom: "1px solid black", height: "29px" }}>
                          {order?.amount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
           </>
          ))}
    
  </div>
  )
}

export default CategorySummary