import axios from "axios"
import { HEADERS } from "../../../../API/UrlAndPaths"
import { get_order_list, get_payment_wallet_list, get_shift_list, single_view_pos_order, single_view_pos_shift } from "./GeneralSlice"
import store from "../../../../Redux/store"
import { setIsLoading } from "../../kitchenOrderTicket/kitchenOrderTicketSlice"
import { errorSnackMsg } from "../../../Custom Hooks/SnackkBarUtilities"

//list of order//
export const ListPosOrdersAPI = async (body) => {
  setIsLoading!==undefined&&setIsLoading(true)

    await axios.post("foodManagement/orderList", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch( get_order_list(res.data))
                setIsLoading!==undefined&&setIsLoading(false)

            }
        })
        .catch((err) => {
            store.dispatch( get_order_list(undefined))
            setIsLoading!==undefined&&setIsLoading(false)
            errorSnackMsg(err.response.data || "Network Failed")


        })
}
//pos order single view
export const SingleViewPosOrderAPI = async (body) => {
    await axios.post("foodManagement/viewposordersingle", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(single_view_pos_order(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(single_view_pos_order(undefined))


        })
}
//list of payment and  wallet 

export const ListPosWalletAndPaymentAPI = async (body) => {
  setIsLoading!==undefined&&setIsLoading(true)

    await axios.post("foodManagement/lispaymentandwalletbyshiftid",body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(get_payment_wallet_list(res.data))
                setIsLoading!==undefined&&setIsLoading(false)

            }
        })
        .catch((err) => {
            store.dispatch(get_payment_wallet_list(undefined))
            setIsLoading!==undefined&&setIsLoading(false)
            errorSnackMsg(err.response.data || "Network Failed")


        })
}

//list of shift//
export const ListPosShiftAPI = async (body,setIsLoading) => {
  setIsLoading!==undefined&&setIsLoading(true)

    await axios.post("foodManagement/viewshiftlist",body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(get_shift_list(res.data))
  setIsLoading!==undefined&&setIsLoading(false)


            }
        })
        .catch((err) => {
            store.dispatch(get_shift_list(undefined))
  setIsLoading!==undefined&&setIsLoading(false)
  errorSnackMsg(err.response.data || "Network Failed")


        })
}
//pos shift single view
export const SingleViewPosShiftAPI = async (body) => {
    await axios.post("foodManagement/viewshiftsingle", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(single_view_pos_shift(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(single_view_pos_shift(undefined))


        })
}